import React from "react"
import AppBar from "@mui/material/AppBar";
import {Button, createTheme, Link, Stack, ThemeProvider, Toolbar, Typography} from "@mui/material";

interface Props {
    title: string
    showNavigation?: boolean
}

const HeaderBar = (props: Props) => {

    const theme = createTheme();
    theme.typography.h3 = {
        fontSize: '1.4rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
        },
        '@media (min-width:750px)': {
            fontSize: '1.6rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.8rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.6rem',
        },
    };

    return <AppBar position="fixed" sx={{background: "rgb(51,51,51)"}}>
        <Toolbar sx={{justifyContent: "center", textAlign: "center"}}>
            <Link href="/" mr={"2rem"}>
                <img alt="Silvertip Tours" style={{height: "58px", marginTop: "10px"}}
                     src="/assets/silvertip-logo-notext.png"/>
            </Link>
            <ThemeProvider theme={theme}>
                <Typography variant="h3" sx={{fontWeight: 400, color: 'white', fontVariant: 'small-caps'}}>
                    {props.title}
                </Typography>
            </ThemeProvider>
        </Toolbar>
        {props.showNavigation && (
            <Stack direction={"row"} sx={{
                justifyContent: "center",
                textAlign: "center",
                background: "white"
            }}>
                <Button variant="text" color="primary" size="large">Reiseverlauf</Button>
                <Button variant="text" color="primary" size="large">Leistungen</Button>
                <Button variant="text" color="primary" size="large">Termine und Preise</Button>
                <Button variant="text" color="primary" size="large">Informationen</Button>
                <Button variant="text" color="primary" size="large">Impressionen</Button>
            </Stack>
        )}
    </AppBar>
}

export default HeaderBar