import React, {useEffect, useState} from "react"
import TourApi from "../../Api/TourApi";
import {
    Container,
    Typography,
    useScrollTrigger
} from "@mui/material";
import HeaderBar from "./HeaderBar";
import CardTour from "./CardTour";
import HeaderGroup from "./HeaderGroup";

interface Props {
    id: number
}

const ViewTourGroup = (props: Props) => {

    const [tourGroup, setTourGroup] = useState(TourApi.TourGroupDefinition)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading)
            TourApi.GetPublicGroup(props.id).then((d: any) => {
                setTourGroup(d.payload)
                setLoading(false)
            })
    }, [tourGroup, loading, props.id])

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 600
    });

    return <>
        {trigger ? <HeaderBar title={tourGroup.name}/> : ""}
        <HeaderGroup
            title={tourGroup.name}
            subtitle={tourGroup.subtitle}
            link={tourGroup.headerPhoto ? tourGroup.headerPhoto.link : ''}
        />
        <Container>
            <Typography variant="body1" mt={4} textAlign="justify" fontSize={"1.2rem"}>
                {tourGroup.description}
            </Typography>
            {tourGroup.tour && (
                <Typography variant={"h5"} mt={2} color={"rgb(51,51,51,0.8)"}>
                    {tourGroup.tour.length + ' Reisen in ' + tourGroup.name}
                </Typography>
            )}
            <div className="pd1"/>
            <CardTour tours={tourGroup.tour}/>
        </Container>
    </>
}

export default ViewTourGroup