import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    CardMedia, Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Stack,
    TextField,
} from "@mui/material";
import ResizeBox from "../../../Components/ResizeBox";
import {useNavigate, useParams} from "react-router-dom";
import {useImmer} from "use-immer";
import PhotoApi, {PhotoSetType, PhotoType} from "../../../Api/PhotoApi";
import ProgressiveImage from "../../../Components/ProgressiveImage";
import ConfigGeneral from "../../../Config/ConfigGeneral";
import Message from "../../../Components/Message";
import DialogSelectPhoto from "./DialogSelectPhoto";
import MgmtPageTitle from "../Components/MgmtPageTitle";
import MgmtPageActions from "../Components/MgmtPageActions.tsx";
import {useTranslation} from "react-i18next";

const PhotoSetEdit = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const params = useParams()

    const [photoSet, setPhotoSet] = useImmer<PhotoSetType>(PhotoApi.DefaultPhotoSet)
    useEffect(() => {
            if (!photoSet.id && params.id) {
                PhotoApi.GetPhotoSetDetail(params.id)
                    .then((data: any) => {
                        const payload = data.payload
                        setPhotoSet(payload)
                        setFinalSelection(payload.photo)
                    })
                    .catch((error: string) => {
                        console.log("error: " + error)
                    })
            }
        }, [params.id, photoSet.id, setPhotoSet]
    )

    interface MessageProps {
        severity?: string
        message?: string
        open: boolean
    }

    const emptyMessage: MessageProps = {
        severity: "success",
        message: "I'm ok",
        open: false
    }

    const [message, setMessage] = useState<MessageProps>(emptyMessage)

    const canSave = () => {
        return !!(photoSet && photoSet.name)
    }

    const [changed, setChanged] = useState(false)
    const onChange = (name: string, value: any) => {
        setPhotoSet((draft) => {
            switch (name) {
                case "name": {
                    draft.name = value
                    break
                }
                case "description": {
                    draft.description = value
                    break
                }
            }
        })
        setChanged(true)
    }

    const [finalSelection, setFinalSelection] = useState<PhotoType[]>(PhotoApi.DefaultPhoto)
    const onSelectChange = (id: number, checked: boolean) => {
        const newSelection = [...finalSelection]
        if (checked) {
            const photo = photoSet.photo.find((item) => item.id === id) ?
                photoSet.photo.find((item) => item.id === id) : null
            if (photo) newSelection.push(photo)
        } else {
            const index = newSelection.findIndex((item) => item.id === id);
            if (index > -1) {
                newSelection.splice(index, 1);
            }
        }
        setFinalSelection(newSelection)
        setChanged(true)
    }

    const onSave = (close?: boolean) => {
        const delay = (ms: number) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        const body = {
            ...photoSet,
            photo: finalSelection
        }

        PhotoApi.PersistPhotoSet(body).then(() => {
            if (close) {
                delay(500).then(() => {
                    setPhotoSet(PhotoApi.DefaultPhotoSet)
                    navigate("/mgmt/photoSet")
                })
            } else {
                setMessage({
                    severity: "success",
                    message: `Changes for photoSet "${photoSet.name}" saved successfully `,
                    open: true
                })
                setChanged(false)
                setPhotoSet(PhotoApi.DefaultPhotoSet)
            }
        }).catch((error: string) => {
            setMessage({
                severity: "error",
                message: `Error saving changes ${error}`,
                open: true
            })
        })
    }

    const [showDialogSelectPhoto, setShowDialogSelectPhoto] = useState(false)

    const onAddPhoto = (newPhoto: any) => {
        console.log("onAddPhoto :" + JSON.stringify(newPhoto))

        const newPhotoSet = [...photoSet.photo]
        newPhotoSet.push(...newPhoto)
        setPhotoSet((draft) => {
            draft.photo = newPhotoSet
        })

        const newSelection = finalSelection.length > 0 ? [...finalSelection] : []
        newSelection.push(...newPhoto)
        setFinalSelection(newSelection)

        setChanged(true)
    }

    const actions = [
        {label: "photo.add", onClick: () => setShowDialogSelectPhoto(true)},
    ]

    return <ResizeBox.Box>
        <MgmtPageTitle label={params.id ? "photo.editPhotoSet" : "photo.createPhotoSet"} icon={"photo_library"}/>
        <div className='pd1'/>
        <Stack gap={1} sx={{minHeight: ResizeBox.Height({offset: 200})}}>
            <TextField
                autoFocus
                required
                fullWidth
                value={photoSet && photoSet.name ? photoSet.name : ""}
                margin="dense"
                id="name"
                name="name"
                label="Photo Name"
                helperText="Original Name of photo, just used as alternate text"
                type="text"
                variant="standard"
                onChange={(element) => onChange(element.target.name, element.target.value)}
            />
            <TextField
                fullWidth
                multiline
                rows={3}
                value={photoSet && photoSet.description ? photoSet.description : ""}
                margin="dense"
                id="description"
                name="description"
                helperText="Addional description"
                label="Description"
                type="text"
                variant="filled"
                onChange={(element) => onChange(element.target.name, element.target.value)}
            />
            <Stack direction="row">
                <MgmtPageActions actions={actions}/>
            </Stack>
            <Stack direction="row" gap={1} useFlexGap flexWrap="wrap" mt={2}>
                {photoSet.photo.map((p: any) => {
                    return <Card key={p.id}>
                        <CardMedia>
                            <ProgressiveImage src={p.thumb} placeholder={ConfigGeneral.URL_DUMMY_PHOTO}
                                              style={{height: "120px"}}/>
                        </CardMedia>
                        <CardContent sx={{
                            padding: "0px 10px 0px 10px",
                            "&:last-child": {
                                paddingBottom: 0
                            }
                        }}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={!!finalSelection.find((item) => item.id === p.id)}
                                            onChange={(_, d) => onSelectChange(p.id, d)}
                                        />
                                    }
                                    label="Selected"
                                />
                            </FormControl>
                        </CardContent>
                    </Card>
                })}
            </Stack>
        </Stack>
        <Divider sx={{mt: 1}}/>
        <Stack direction="row-reverse" sx={{paddingBottom: 4}}>
            <Button onClick={() => onSave(true)} disabled={!canSave() || !changed}>
                {params.id ? t("generics.updateClose") :  t("generics.createClose")}
            </Button>
            <Button onClick={() => onSave(false)} disabled={!canSave() || !changed}>
                {params.id ? t("generics.update") : t("generics.create")}
            </Button>
            <Button onClick={() => navigate("/mgmt/photoSet")}>
                {t("generics.cancel")}
            </Button>
        </Stack>
        <Message message={message} close={() => setMessage({open: false})} open={message.open}/>
        <DialogSelectPhoto open={showDialogSelectPhoto} show={setShowDialogSelectPhoto} onSelect={onAddPhoto} multirow/>
    </ResizeBox.Box>
}

export default PhotoSetEdit