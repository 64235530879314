import API from "./Api";

const TourApi: any = {

    TourGroupDefinition: {
        id: 0,
        route: {
            slug: "",
            route: "",
            pointTo: "",
            active: false
        },
        name: '',
        subtitle: '',
        description: '',
        active: false,
        orderID: 0,
        headerPhoto: null,
        cardPhoto:null,
    },

    TourDefinition: {
        id: 0,
        route: {
            slug: '',
            route: '',
            pointTo: '',
            active: false
        },
        name: '',
        description: '',
        internalNote: '',
        active: false,
        promotion: false,
        code: '',
        headerPhoto: null,
        cardPhoto: null,
        startPlace: '',
        finishPlace: '',
        accommodation: '',
        startPrice: 0.0,
        nights: 0,
        group: null,
        country: null,
        language: null,
        tag: [],
        season: {
            yearAround: false,
            january: false,
            february: false,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            august: false,
            september: false,
            october: false,
            november: false,
            december: false
        },
        character: "",
        guidedBy: "",
        program: [],
        include: [],
        exclude: [],
        dates: [],
        dateAddon: [],
        individualAddon: [],
        price: [],
        info:[],
        textInfo:[]
    },

    GetTours: () => {
        return API.Request('/tour')
    },

    GetTourDetail: (id: number) => {
        return API.Request('/tour/' + id)
    },

    PersistTour: async (data: any) => {
        return API.Persist(data.id, '/tour', data)
    },

    GetTourGroupDetail: (id: number) => {
        return API.Request('/tour/group/' + id)
    },

    GetGroups: () => {
        return API.Request('/tour/group')
    },

    GetPublicGroup: (id?: number) => {
        if (id) {
            return API.RequestPublic("/view-tour-group/" + id)
        } else {
            return API.RequestPublic("/view-tour-group")
        }
    },

    GetPublicTour: (id?: number) => {
        if (id) {
            return API.RequestPublic("/view-tour/" + id)
        } else {
            return null
        }
    },

    PersistGroup: async (data: any) => {
        return API.Persist(data.id, '/tour/group', data)
    },

    GetTextDetail: (id: number) => {
        return API.Request('/tour/text/' + id)
    },

    PersistText: async (data: any) => {
        return API.Persist(data.id, '/tour/text', data)
    },

    DeleteText: async (id: number) => {
        return API.Delete('/tour/text/' + id)
    },

    DeleteGroup: async (id: number) => {
        return API.Delete('/tour/group/' + id)
    },

    DeleteTour: async (id: number) => {
        return API.Delete('/tour/' + id)
    }
}

export default TourApi