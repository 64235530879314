import React from "react"
import {Paper, Typography} from "@mui/material";
import ProgramTimeline from "../../Components/ProgramTimeline";


interface Props {
    program: any
}

const TourProgram = (props: Props) => {
    return <Paper elevation={0} sx={{p: 2, mt: "1rem"}} id='tour-program'>
        <Typography variant='h6'>
            Reiseverlauf
        </Typography>
        <ProgramTimeline program={props.program}/>
    </Paper>

}

export default TourProgram