import React, {useEffect, useState} from "react";
import ResizeBox from "../../../Components/ResizeBox";
import PhotoApi from "../../../Api/PhotoApi";
import {DataGrid, GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import ResponsiveImage from "../../../Components/ResponsiveImage";
import {useNavigate} from "react-router-dom";
import MgmtPageTitle from "../Components/MgmtPageTitle";
import {ButtonGroup, IconButton} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Message from "../../../Components/Message.tsx";
import MgmtPageActions from "../Components/MgmtPageActions.tsx";

const PhotoSet = () => {

    const navigate = useNavigate();

    interface MessageProps {
        severity?: string
        message?: string
        open: boolean
    }

    const emptyMessage: MessageProps = {
        severity: "success",
        message: "I'm ok",
        open: false
    }

    const [message, setMessage] = useState<MessageProps>(emptyMessage)

    const [photoSets, setPhotoSets] = useState([])
    useEffect(() => {
        PhotoApi.GetPhotoSets().then((data: any) => {
            if (data) {
                setPhotoSets(data.payload.map((p: any) => {
                    return {...p, count: p.photo.length, actionID: p.id}
                }));
            }
        })
    }, []);

    const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([])
    const onRowClick = (id: any) => {
        setRowSelection(id)
    }

    const isDisabled = (id:number) => {
        return !rowSelection.includes(id)
    }

    const onDeletePhotoSet = (id: number) => {
        PhotoApi.DeletePhotoSet(id).then((data: any) => {
            if (data) {
                setPhotoSets(photoSets.filter((p: any) => p.id !== id))
                setMessage({
                    severity: "success",
                    message: "PhotoSet deleted",
                    open: true
                })
            }
        })
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "photo",
            headerName: "Image",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return params.value.length > 0 && params.value[0].thumb ?
                    <ResponsiveImage src={params.value[0].thumb} sx={{height: "50px"}}/> : ""
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
            headerClassName: 'tableHeader',
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'count',
            headerName: '#Photos',
            headerClassName: 'tableHeader',
        },
        {
            field: "actionID",
            headerName: "",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
                return <ButtonGroup variant="text" aria-label="Photo actoin button group">
                    <IconButton sx={{m: 0}} onClick={() => {
                        navigate("/mgmt/photoSet/edit/" +  params.value, {replace: true})
                    }}>
                        <EditOutlinedIcon color="action" fontSize="small"/>
                    </IconButton>
                    <IconButton sx={{m: 0}} onClick={() => {
                        onDeletePhotoSet(params.value)
                    }} disabled={isDisabled(params.value)}>
                        <DeleteIcon color={"warning"} fontSize="small"/>
                    </IconButton>
                </ButtonGroup>
            }
        },
    ]

    const actions = [
        {label: "photo.createPhotoSet", onClick: () => navigate("/mgmt/photoSet/edit", {replace: true})},
    ]

    return <ResizeBox.Box>
        <MgmtPageTitle label={"photo.photoSets"} icon={"photo_library"}/>
        <MgmtPageActions actions={actions}/>
        <DataGrid
            sx={{
                height: ResizeBox.Height,
                ".MuiDataGrid-columnHeaderTitleContainer": {
                    backgroundColor: "aliceblue",
                },
            }}
            autoPageSize
            rows={photoSets}
            columns={columns}
            checkboxSelection
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            rowHeight={39}
            columnHeaderHeight={39}
            onRowSelectionModelChange={(i: any) => {
                onRowClick(i)
            }}
            rowSelectionModel={rowSelection}
        />
        <Message message={message} close={() => setMessage({open: false})} open={message.open}/>
    </ResizeBox.Box>

}

export default PhotoSet