import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import "./Config/ConfigI18n.tsx";
import {useTranslation} from "react-i18next";

import AppBar from '@mui/material/AppBar';
import {
    Box,
    Button,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Slide,
    Stack,
    Toolbar,
    Typography,
    useScrollTrigger
} from "@mui/material";
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Login from "./Components/Login";
import {jwtDecode} from "jwt-decode";

const AppHeader = () => {

    const {t} = useTranslation();

    const getLocalToken = () => {
        const localToken = localStorage.getItem("st-jwt") !== null ? localStorage.getItem("st-jwt") : ""
        return String(localToken)
    }

    const tokenExp = () => {
        const token = getLocalToken()
        if (!token) return true
        const decodedToken = jwtDecode(token)
        const now = Date.now() / 1000
        const exp = decodedToken && decodedToken.exp ? decodedToken.exp : 0
        return Math.round(exp - now) < 0
    }

    const [openLoginDialog, setOpenLoginDialog] = useState(false)
    const [isExpired, setExpired] = useState(tokenExp())
    const navigate = useNavigate();
    const url = useLocation()

    const onChangeLogin = (token: string) => {
        const decodedToken = jwtDecode(token)
        const now = Date.now() / 1000
        const exp = decodedToken && decodedToken.exp ? decodedToken.exp : 0
        if (Math.round(exp - now) > 0) {
            localStorage.setItem("st-jwt", token)
            setExpired(false)
            setOpenLoginDialog(false)
        }
    }

    const isManagement = () => {
        return url.pathname.includes("/mgmt")
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const token = getLocalToken()

            if (!token) {
                if (url.pathname.includes("/mgmt")) navigate("/", {replace: true})
                setExpired(true)
                return
            }

            const exp = (token: string) => {
                const decodedToken = jwtDecode(token)
                const now = Date.now() / 1000
                const exp = decodedToken && decodedToken.exp ? decodedToken.exp : 0
                return Math.round(exp - now)
            }

            const expiresIn = exp(token)

            if (expiresIn < 0) {
                localStorage.removeItem("st-jwt")
                setExpired(true)
            }
        }, 5000)

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [navigate, url.pathname])

    useEffect(() => {
        if (isExpired) {
            //if (url.pathname.includes("/mgmt")) navigate("/", {replace: true})
            navigate("/", {replace: true})
            setOpenLoginDialog(true)
        }
    }, [isExpired, url.pathname, navigate])

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const pages = [
        {page: "Unsere Reisen", ref: "reisen", icon: <CardTravelOutlinedIcon/>},
        {page: "Wohin soll es gehen", ref: "search", icon: <TravelExploreOutlinedIcon/>},
        {page: "Kontakt", ref: "kontakt", icon: <AlternateEmailOutlinedIcon/>}
    ]

    const RenderProfile = () => {
        return <Stack direction="row" gap={1}>
            {!isExpired && !isManagement() ?
                <Button
                    size="small"
                    onClick={() => {
                        navigate('/mgmt', {replace: true})
                    }}
                    startIcon={<SettingsOutlinedIcon/>}
                >
                    {t("appHeader.settings")}
                </Button>
                :
                ""
            }
            {isExpired ?
                <Button
                    size="small"
                    onClick={() => {
                        setOpenLoginDialog(true);
                    }}
                    startIcon={<LoginOutlinedIcon/>}
                >
                    {t("appHeader.login")}
                </Button>
                :
                <Button
                    size="small"
                    onClick={() => {
                        localStorage.removeItem("st-jwt")
                        setExpired(true)
                        if (isManagement()) navigate('/', {replace: true})
                    }}
                    startIcon={<LogoutOutlinedIcon/>}
                >
                    {t("appHeader.logout")}
                </Button>
            }
        </Stack>
    }

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 200,
    });

    const AppBarManagement = () => {
        return <AppBar
            position="fixed"
            elevation={0}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                background: "white",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
            }}
        >
            <Toolbar disableGutters>
                <Box sx={{flexGrow: 1}}>
                    <Link href="/">
                        <img
                            alt="Silvertip Tours"
                            style={{height: "5vh", marginLeft: '30px'}}
                            src="/assets/silvertip-logo-notext.png"/>
                    </Link>
                </Box>
                <Box sx={{flexGrow: 1}}>
                    <Typography
                        variant="h5"
                        sx={{
                            mr: 2,
                            color: 'gray',
                            textDecoration: 'none',
                        }}>
                        {t("appHeader.settings")}
                    </Typography>
                </Box>
                <Box sx={{flexGrow: 0}} marginTop="-18px" marginRight="0.5em">
                    <RenderProfile/>
                </Box>
            </Toolbar>
        </AppBar>
    }

    const RenderLogo = ({height}: any) => {
        return <Link href="/">
            <img alt="Silvertip Tours" style={{height: height}}
                 src="/assets/silvertip-logo-header.png"/>
        </Link>
    }

    const RenderMenu = ({fontSize}: any) => {
        return <>
            {pages.map((p) => {
                return <Button
                    key={p.ref}
                    sx={{color: 'rgb(51,51,51,0.8)', fontSize: fontSize, paddingLeft: "1em"}}
                    size="large"
                    startIcon={p.icon}
                >
                    {p.page}
                </Button>
            })}
        </>
    }

    const AppBarPublic = () => {
        return <Slide appear={false} direction="down" in={!trigger}>
            <AppBar position="sticky" style={{background: "white"}}>
                <Toolbar disableGutters>
                    <Box sx={{
                        flexGrow: 0,
                        display: {xs: 'none', md: 'none', lg: 'flex'},
                        paddingLeft: "2em",
                        marginTop: "1em"
                    }}>
                        <RenderLogo height="12vh"/>
                    </Box>
                    <Box sx={{
                        flexGrow: 0,
                        display: {xs: 'none', md: 'flex', lg: 'none'},
                        paddingTop: "0.5rem"
                    }}>
                        <RenderLogo height="8vh"/>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginTop: {xs: "-30px", md: "-55px", lg: "-6em"},
                        marginRight: "0.5rem"
                    }}>
                        <RenderProfile/>
                    </Box>
                </Toolbar>
                <Toolbar disableGutters sx={{
                    marginTop: {xs: "-60px", md: "-33px", lg: "-2em"}
                }}>
                    <Box sx={{
                        flexGrow: 1,
                        display: {xs: 'none', md: 'none', lg: 'flex'},
                        justifyContent: "center"
                    }}>
                        <RenderMenu fontSize="1.1rem"/>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        display: {xs: 'none', md: 'flex', lg: 'none'},
                        justifyContent: "center"
                    }}>
                        <RenderMenu fontSize="1rem"/>
                    </Box>

                    {/* Mobile XS Section */}
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu id="menu-appbar"
                              anchorEl={anchorElNav}
                              anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                              }}
                              keepMounted
                              transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                              }}
                              open={Boolean(anchorElNav)}
                              onClose={handleCloseNavMenu}
                              sx={{
                                  display: {xs: 'block', md: 'none'},
                              }}
                        >
                            {pages.map((p) => (
                                <MenuItem key={p.ref} onClick={handleCloseNavMenu}>
                                    <ListItemIcon>
                                        {p.icon}
                                    </ListItemIcon>
                                    <ListItemText>{p.page}</ListItemText>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <img alt="Silvertip Tours" className="logoSx" style={{height: "8vw"}}
                             src="/assets/silvertip-logo-notext.png"/>
                    </Box>
                </Toolbar>
            </AppBar>
        </Slide>
    }

    return <>
        {isManagement() ? <AppBarManagement/> : <AppBarPublic/>}
        <Login open={openLoginDialog} close={() => setOpenLoginDialog(false)} onChange={onChangeLogin}/>
    </>
}

export default AppHeader