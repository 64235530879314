import React from "react"
import {Button, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import AddBox from "@mui/icons-material/AddBox";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import DeleteIcon from "@mui/icons-material/Delete";

interface MgmtAction {
    label: string
    onClick: any
    disabled?: boolean
    icon?: string
}

interface Props {
    actions: MgmtAction[]
    style?: any
}

const MgmtPageActions = (props: Props) => {

    const {actions} = props
    const {t} = useTranslation();

    const getIcon = (i: string | undefined) => {
        switch (i) {
            case undefined: {
                return <AddBox/>
            }
            case "close" : {
                return <DisabledByDefaultIcon/>
            }
            case "delete": {
                return <DeleteIcon/>
            }
            default : {
                return <HelpCenterIcon/>
            }
        }
    }

    const getColor = (i: string | undefined, d: boolean | undefined) => {
        switch (i) {
            case "close" : {
                return d ? "inherit" : "warning"
            }
            case "delete": {
                return d ? "inherit" : "warning"
            }
            default : {
                return d ? "inherit" : "primary"
            }
        }
    }

    const getStyle = (i: string | undefined) => {
        switch (i) {
            case "close" : {
                return {marginLeft: "auto"}
            }
            default : {
                return {}
            }
        }
    }

    return <Stack direction="row" alignItems="center" gap={1} paddingBottom="0.5em" style={props.style}>
        {actions.map((a, i) => {
            return <Button
                id={a.label}
                key={i}
                color={getColor(a.icon, a.disabled)}
                size="small"
                onClick={a.onClick}
                disabled={a.disabled}
                style ={getStyle(a.icon)}
                startIcon={getIcon(a.icon)}>
                {t(a.label)}
            </Button>
        })}
    </Stack>
}

export default MgmtPageActions