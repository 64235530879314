import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import TourApi from "../../../../Api/TourApi";
import {DataGrid, GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import ResizeBox from "../../../../Components/ResizeBox";
import ResponsiveImage from "../../../../Components/ResponsiveImage";
import "../../../../Config/ConfigI18n.tsx";
import {useTranslation} from "react-i18next";
import MgmtPageTitle from "../../Components/MgmtPageTitle";
import MgmtPageActions from "../../Components/MgmtPageActions";
import {ButtonGroup, IconButton} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import MgmtDialogYesNo from "../../Components/MgmtDialogYesNo.tsx";
import {ContentCopy} from "@mui/icons-material";

const TourOverview = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const [tours, setTours] = React.useState([TourApi.TourDefinition]);
    const [changed, setChanged] = React.useState(false)
    const [showYesNo, setShowYesNo] = React.useState(false);

    useEffect(() => {
        TourApi.GetTours().then((data: any) => {
            if (data) {
                setTours(data.payload.map((p: any) => {
                    return {...p, actionID: p.id}
                }));
            }
            setChanged(false)
        })
    }, [changed]);

    const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([])
    const onRowClick = (id: any) => {
        setRowSelection(id)
    }

    const isDisabled = (id: number) => {
        const tourToDelete = tours.find((tour) => tour.id === id)
        const isTemplate = tourToDelete && tourToDelete.template
        return !rowSelection.includes(id) || isTemplate
    }

    const [toDelete, setToDelete] = React.useState<number | undefined>(undefined)
    const [toDeleteInfo, setToDeleteInfo] = React.useState<string | undefined>(undefined)
    const onDeleteTour = (id: number) => {
        setShowYesNo(true)
        setToDelete(id)
        const tourToDelete = tours.find((tour) => tour.id === id)
        setToDeleteInfo('Soll die Reise ' + tourToDelete.code +
            ' "' + tourToDelete.name + '" wirklich gelöscht werden?')
    }

    const onConfirmation = (yesNo: boolean) => {
        if (yesNo) {
            TourApi.DeleteTour(toDelete).then((data: any) => {
                if (data) {
                    setRowSelection([])
                    setTours(tours.filter((p: any) => p.id !== toDelete))
                    setChanged(true)
                }
            })
        }
        setShowYesNo(false)
        setToDelete(undefined)
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "cardPhoto",
            headerName: t("mgmtTourOverview.image"),
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return params.value ? <ResponsiveImage src={params.value.thumb} sx={{height: "50px"}}/> : <></>
            }
        },
        {
            field: 'code',
            headerName: t("mgmtTourOverview.code"),
            headerClassName: 'tableHeader',
            align: "left",
            headerAlign: "left",
            width: 100
        },
        {
            field: 'name',
            headerName: t("mgmtTourOverview.name"),
            headerClassName: 'tableHeader',
            flex: 0.8
        },
        {
            field: 'description',
            headerName: t("mgmtTourOverview.description"),
            headerClassName: 'tableHeader',
            flex: 0.8
        },
        {
            field: 'route',
            headerName: t("mgmtTourOverview.slug"),
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return params.value ? params.value.slug : ""
            }
        },
        {
            field: "active",
            headerName: t("mgmtTourOverview.active"),
            align: "center",
            headerAlign: "center",
            headerClassName: "tableHeader",
            width: 80,
            renderCell: (params) => {
                return params.value ? t("generics.yes") : t("generics.no")
            }
        },
        {
            field: "actionID",
            headerName: "",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "right",
            headerAlign: "right",
            width: 150,
            renderCell: (params) => {
                return <ButtonGroup variant="text" aria-label="Photo action button group">
                    <IconButton
                        sx={{m: 0}}
                        onClick={() => {
                            navigate("/mgmt/tour/edit/" + params.value, {replace: true})
                        }}>
                        <EditOutlinedIcon color="action" fontSize="small"/>
                    </IconButton>
                    <IconButton
                        sx={{m: 0}}
                        onClick={() => {
                            navigate("/mgmt/tour/clone/" + params.value, {replace: true})
                        }}>
                        <ContentCopy color="action" fontSize="small"/>
                    </IconButton>
                    <IconButton
                        sx={{m: 0}}
                        onClick={() => {
                            onDeleteTour(params.value)
                        }}
                        disabled={isDisabled(params.value)}>
                        <DeleteIcon color={isDisabled(params.value) ? "disabled" : "warning"} fontSize="small"/>
                    </IconButton>
                </ButtonGroup>
            }
        },
    ];

    const actions = [{label: "mgmtTourOverview.addATour", onClick: () => navigate("/mgmt/tour/edit", {replace: true})}]

    return <ResizeBox.Box>
        <MgmtPageTitle label={"mgmtSkeleton.tourManagement"} icon={"card_travel"}/>
        <MgmtPageActions actions={actions}/>
        <DataGrid
            sx={{height: ResizeBox.Height}}
            autoPageSize
            rows={tours}
            columns={columns}
            checkboxSelection
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            onRowSelectionModelChange={(i: any) => {
                onRowClick(i)
            }}
            rowSelectionModel={rowSelection}
            disableMultipleRowSelection={true}
            rowHeight={39}
            columnHeaderHeight={39}
        />
        <MgmtDialogYesNo
            open={showYesNo}
            onClose={onConfirmation}
            title={t('mgmtTourEdit.deleteTour')}
            content={toDeleteInfo}
        />
    </ResizeBox.Box>
}

export default TourOverview