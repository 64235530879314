import React from "react"
import {List, ListItem, Paper, Typography} from "@mui/material";

interface Props {
    title: string
    items: any
    textModules: any
}

const TourService = (props: Props) => {

    const items = props.items ? props.items.map((item: any) => {
        return {line: item.item}
    }) : []
    const modules = props.textModules ? props.textModules.map((module: any) => {
        return {line: module.text}
    }) : []
    const services = [...items, ...modules]

    return <Paper elevation={0} sx={{background: "rgb(69,147,198,0.2)", p: 2}} id='tour-service'>
        <Typography variant='h6'>{props.title}</Typography>
        {services && (
            <List sx={{listStyleType: 'disc', listStylePosition: 'outside', pl: 2}}>
                {services.map((item: any, index: number) => {
                    return <ListItem key={index} sx={{ display: "list-item", pl: 1, pt: 0 }} >
                        <Typography variant="body1" sx={{display: "contents", ml:1 }}>
                            {item.line}
                        </Typography>
                    </ListItem>
                })}
            </List>
        )}
    </Paper>

}

export default TourService