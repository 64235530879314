import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import React from "react";

const ConfigTour = {

    ProgramTypeOptions: [
        {value: "day", label: "program.day", icon: <ExploreOutlinedIcon/>, color: "rgb(69,147,198,0.8)" },
        {value: "info", label: "program.info", icon: <InfoOutlinedIcon/>, color: "rgb(247,205,71,1)"  },
        {value: "arrival", label: "program.arrival", icon: <FlightTakeoffIcon/>, color: "rgb(102,102,102,0.8)"  },
        {value: "departure", label: "program.departure", icon: <FlightLandIcon/>, color: "rgb(102,102,102,0.8)"  },
    ]

}
export default ConfigTour