import React, {useEffect, useState} from "react"
import {useImmer} from "use-immer";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField
} from "@mui/material";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from "dayjs";
import 'dayjs/locale/de';
import Transform from "../../../../Common/Transform";
import {TFunction} from "i18next";
import InputPrice from "./Components/InputPrice";
import utils from "../../../../Common/Utils";
import MgmtPageActions from "../../Components/MgmtPageActions.tsx";
import {Delete} from "@mui/icons-material";

interface Props {
    t: TFunction
    nights: number
    price: number
    dates: any
    onChangeDates: any
}

const TourEditDates = (props: Props) => {

    const t = props.t

    interface TourDate {
        id: number | null
        actionId: string | null
        start: Date | null
        end: Date | null
        price: number
        nights: number
        note: string
        toDelete: boolean
    }

    const tourDate: TourDate = {
        id: null,
        actionId: null,
        start: null,
        end: null,
        price: 0,
        nights: 0,
        note: "",
        toDelete: false
    }

    const [dates, setDates] = useImmer<TourDate[]>([])
    const [dateToAdd, setDateToAdd] = useImmer<TourDate>(tourDate)
    const [showAddDate, setShowAddDate] = useState(false)
    const [edit, setEdit] = useState("")
    const [updates, setUpdates] = useState(false)

    useEffect(() => {
        if (props.dates) {
            // transform the date values as string to Date values, set actionID
            const modifiedDates = props.dates.map((d: any) => {
                let m = {...d}
                m.start = new Date(d.start)
                m.end = new Date(d.end)
                m.actionId = utils.makeId(6)
                return m
            })
            setDates(modifiedDates)
        }
    }, [setDates, props])

    useEffect(() => {
        if (updates) {
            const notDeletedNew = dates.filter(d => !(!d.id && d.toDelete))
            props.onChangeDates(notDeletedNew)
            setUpdates(false)
        }
    }, [updates, props, dates])

    const sortDates = (dates: TourDate[]) => {
        const toSort = dates.map((d) => {
            return d
        })
        return toSort.sort((a, b) => {
            return isTime(a.start) - isTime(b.start)
        })
    }

    const calcEndDate = (days: number, start: Date) => {
        return dayjs(start).add(days, "day").toDate()
    }

    const calcDaysBetween = (start: Date, end: Date) => {
        if (!start || !end) return props.nights
        return dayjs(end).diff(dayjs(start), "day")
    }

    const onChange = (key: string, value: any) => {
        if (!value) return
        switch (key) {
            case "start": {
                setDateToAdd(draft => {
                    draft.start = value
                    if (value) {
                        draft.end = dateToAdd.end && dateToAdd.end.getTime() ?
                            dateToAdd.end : calcEndDate(props.nights, value)
                        draft.nights = calcDaysBetween(value, draft.end)
                        if (draft.nights < props.nights) {
                            draft.end = calcEndDate(props.nights, value)
                            draft.nights = calcDaysBetween(value, draft.end)
                        }
                    }
                })
                break
            }
            case "end": {
                setDateToAdd(draft => {
                    draft.end = value
                    if (value) {
                        draft.start = dateToAdd.start && dateToAdd.start.getTime() ?
                            dateToAdd.start : calcEndDate(props.nights * -1, value)
                    }
                    if (dateToAdd.start && value) {
                        draft.nights = calcDaysBetween(dateToAdd.start, value)
                        if (draft.nights < props.nights) {
                            draft.start = calcEndDate(props.nights, value)
                            draft.nights = calcDaysBetween(value, draft.start)
                        }
                    }
                })
                break
            }
            case "note": {
                setDateToAdd(draft => {
                    draft.note = value
                })
                break
            }
        }
        setDates(dates)
    }

    const saveDate = (action: string) => {
        const newDates = dates.map((d) => {
            return d
        })
        switch (action) {
            case "save": {
                newDates.push({...dateToAdd, actionId: utils.makeId(6)})
                break
            }
            case "update": {
                const index = newDates.findIndex((d) => d.actionId === dateToAdd.actionId)
                newDates[index] = dateToAdd
                setEdit("")
                break
            }
        }
        setDates(sortDates(newDates))
        setDateToAdd(tourDate)
        setUpdates(true)
    }

    const onDeleteDate = (actionId: string) => {
        if (dates) {
            const newDates = dates.map((d: any) => {
                if (d.actionId === actionId) {
                    return {...d, toDelete: true}
                }
                return {...d}
            })
            setDates(newDates)
            setUpdates(true)
        }
    }

    const onEditDate = (actionId: string) => {
        const toEdit = dates.find((d) => d.actionId === actionId)
        if (toEdit) {
            setDateToAdd(toEdit)
        } else {
            return
        }
        setEdit(actionId)
        setShowAddDate(true)
    }

    const changePrice = (name: string, value: string) => {
        console.log(name)
        setDateToAdd(draft => {
            draft.price = Number(value)
        })
    }

    const isTime = (t: Date | null) => {
        if (t === null) return 0
        const tt = new Date(t)
        return tt.getTime()
    }

    const getRows = () => {
        return dates.filter((d) => !d.toDelete)
    }

    const canSave = () => {
        const startTime = dateToAdd.start && dateToAdd.start.getTime() ? dateToAdd.start.getTime() : 0
        const startEnd = dateToAdd.end && dateToAdd.end.getTime() ? dateToAdd.end.getTime() : 0
        const now = new Date().getTime()
        const start = !(startTime < now)
        const end = !(startEnd < now)
        const diff = (startTime < startEnd)
        return !!(dateToAdd.start && dateToAdd.end && dateToAdd.nights > 0 && dateToAdd.price && start && end && diff)
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "start",
            headerName: t("mgmtTourEdit.startDate"),
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return params.value ? Transform.FormatDate(params.value) : ""
            }
        },
        {
            field: "end",
            headerName: t("mgmtTourEdit.endDate"),
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return params.value ? Transform.FormatDate(params.value) : ""
            }
        },
        {
            field: "nights",
            headerName: t("generics.nights"),
            headerClassName: 'tableHeader',
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return <Stack direction="row" justifyContent="center" alignItems="center"
                              gap={1}>
                    {params.value !== props.nights ?
                        <ErrorOutlineIcon fontSize="small" color="warning"/> : ""}
                    {params.value}
                </Stack>

            }
        },
        {
            field: "note",
            headerName: t("generics.note"),
            headerClassName: 'tableHeader',
            flex: 1,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "price",
            headerName: t("generics.pPax"),
            headerClassName: 'tableHeader',
            align: "right",
            headerAlign: "right",
            flex: 0.5,
            renderCell: (params) => {
                return Transform.FormatPrice(params.value)
            }
        },
        {
            field: "actionId",
            headerName: "",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
                return <Stack direction="row">
                    <IconButton sx={{m: 0}} onClick={() => onEditDate(params.value)}>
                        <EditOutlinedIcon color="action" fontSize="small"/>
                    </IconButton>
                    <IconButton sx={{m: 0}} onClick={() => onDeleteDate(params.value)}>
                        <Delete color="warning" fontSize="small"/>
                    </IconButton>
                </Stack>
            }
        },
    ]

    const DialogAddDate = () => {
        // Set start price as default
        if (dateToAdd.price === 0) {
            setDateToAdd(d => {
                d.price = props.price
            })
        }
        return <Dialog
            open={showAddDate}
            maxWidth={"lg"}
            onClose={() => {
                setShowAddDate(false)
            }}>
            <DialogTitle>
                {t("mgmtTourEdit.addDate")}
            </DialogTitle>
            <DialogContent>
                <Stack direction="row" gap={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DatePicker
                            autoFocus
                            name="start"
                            label={t("mgmtTourEdit.startDate")}
                            minDate={dayjs()}
                            value={dateToAdd && dateToAdd.start ? dayjs(dateToAdd.start) : null}
                            onChange={() => {
                            }}
                            onAccept={(d) => {
                                const newDate = d ? d.toDate() : null
                                if (newDate) onChange("start", newDate)
                            }}
                            slotProps={{
                                textField: {
                                    onBlur: (e) => {
                                        const newDate = e.target.value ?
                                            dayjs(e.target.value, "DD.MM.YYYY").toDate() : null
                                        if (newDate) onChange("start", newDate)
                                    },
                                    variant: 'standard'
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <TextField
                        sx={{width: "15ch", mt: 0, color: "blue"}}
                        defaultValue={dateToAdd && dateToAdd.nights ? dateToAdd.nights : props.nights}
                        value={dateToAdd && dateToAdd.nights ? dateToAdd.nights : props.nights}
                        margin="dense"
                        id="nights"
                        name="nights"
                        label={t("generics.nights")}
                        type="text"
                        variant="standard"
                        helperText={t("mgmtTourEdit.helpDateCalc")}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DatePicker
                            name="end"
                            label={t("mgmtTourEdit.endDate")}
                            minDate={dateToAdd.start ? dayjs(dateToAdd.start) : dayjs()}
                            value={dateToAdd && dateToAdd.end ? dayjs(dateToAdd.end) : null}
                            onChange={(d) => {
                                const newDate = d ? d.toDate() : null
                                if (newDate) onChange("end", newDate)
                            }}
                            slotProps={{textField: {variant: 'standard',}}}
                        />
                    </LocalizationProvider>
                </Stack>
                <Stack direction="row" gap={2} mt={2}>
                    <InputPrice t={t} value={dateToAdd.price} onChange={changePrice} label={"generics.pPax"}/>
                    <TextField
                        sx={{flex: 1}}
                        value={dateToAdd.note ? dateToAdd.note : ""}
                        margin="dense"
                        id="note"
                        name="note"
                        label={t("generics.note")}
                        type="text"
                        variant="standard"
                        onChange={(e) => onChange("note", e.target.value)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setDateToAdd(tourDate)
                        setShowAddDate(false)
                        setEdit("")
                    }}
                >
                    {t("generics.cancel")}
                </Button>
                {edit ?
                    <Button
                        disabled={!canSave()}
                        onClick={() => {
                            saveDate("update")
                            setShowAddDate(false)
                            setEdit("")
                        }}
                    >
                        {t("generics.update")}
                    </Button>
                    :
                    <>
                        <Button
                            disabled={!canSave()}
                            onClick={() => {
                                saveDate("save")
                                setEdit("")
                            }}
                        >
                            {t("generics.save")}
                        </Button>
                        <Button
                            disabled={!canSave()}
                            onClick={() => {
                                saveDate("save")
                                setDateToAdd(tourDate)
                                setShowAddDate(false)
                            }}
                        >
                            {t("generics.saveClose")}
                        </Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    }

    const RenderGroupDates = () => {
        return <DataGrid
            autoHeight
            getRowId={(row) => {
                return row.actionId
            }}
            rows={getRows()}
            rowHeight={39}
            columnHeaderHeight={39}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
        />
    }

    const actions = [
        {label: "mgmtTourEdit.addDate", onClick: () => setShowAddDate(true)},
    ]

    return <Stack>
        <MgmtPageActions actions={actions}/>
        <RenderGroupDates/>
        {DialogAddDate()}
    </Stack>
}

export default TourEditDates