import React, {useState} from "react";
import DropZone from "../../../../Components/DropZone";
import {Card, CardContent, CardMedia, IconButton, Stack, Typography} from "@mui/material";
import DialogSelectPhoto from "../../Photo/DialogSelectPhoto";
import DialogSelectPhotoSet from "../../Photo/DialogSelectPhotoSet";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import PhotoApi from "../../../../Api/PhotoApi";
import {TFunction} from "i18next";
import MgmtPageActions from "../../Components/MgmtPageActions.tsx";

interface Props {
    t: TFunction
    files: any
    photoSet: any
    onChange: any
    onChangePhotoSet: any
}

const TourEditImpressions = (props: Props) => {
    const [showDialogSelectPhoto, setShowDialogSelectPhoto] = useState(false)
    const [showDialogSelectPhotoSet, setShowDialogSelectPhotoSet] = useState(false)

    const RenderPhotoSet = () => {
        const photoSet = props.photoSet
        return <Stack>
            <Stack direction="row" useFlexGap flexWrap="wrap" gap={1} justifyItems="center" alignItems="center">
                <Typography variant="subtitle2">Photo Set: {photoSet.name}</Typography>
                <IconButton sx={{m: 0}} onClick={() => props.onChangePhotoSet(PhotoApi.DefaultPhotoSet)}>
                    <DeleteOutlineRoundedIcon color="warning" fontSize="small"/>
                </IconButton>
            </Stack>
            <Stack direction="row" useFlexGap flexWrap="wrap" gap={1}>
                {photoSet.photo.map((item: any) => {
                    return <Card
                        sx={{
                            width: 220,
                            height: 220
                        }}
                        key={item.id}>

                        <CardMedia
                            title={item.name}
                            image={item.thumb}
                            sx={{
                                width: 220,
                                height: 220 - 45
                            }}
                        />
                        <CardContent sx={{m: 0.5, p: 0.1}}>
                            <Stack direction="row">
                                <Stack sx={{flex: 1}}>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        noWrap
                                        sx={{
                                            fontSize: "10px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: 220 - 50
                                        }}>
                                        Name: {item.name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        noWrap
                                        sx={{
                                            fontSize: "10px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: 220 - 50
                                        }}>
                                        Id: {item.id} / Link: {item.link}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                })}
            </Stack>
        </Stack>
    }

    const actions = [
        {label: "photo.select", onClick: () => setShowDialogSelectPhoto(true)},
        {label: "photo.selectSet", onClick: () => setShowDialogSelectPhotoSet(true)}
    ]

    return <Stack>
        <MgmtPageActions actions={actions}/>
        <div className="pd1"/>
        <DropZone maxFiles={24} files={props.files} onChange={props.onChange} maxHeight={220} variant="impression"/>
        {props.photoSet && props.photoSet.id ? <RenderPhotoSet/> : ""}
        <DialogSelectPhoto
            open={showDialogSelectPhoto}
            show={setShowDialogSelectPhoto}
            variant="impression"
            onSelect={props.onChange}
            multirow/>
        <DialogSelectPhotoSet
            open={showDialogSelectPhotoSet}
            show={setShowDialogSelectPhotoSet}
            onSelect={props.onChangePhotoSet}/>
    </Stack>
}

export default TourEditImpressions