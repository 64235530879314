import React, {useState} from "react";
import ConfigGeneral from "../Config/ConfigGeneral";
import {useDropzone} from "react-dropzone";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import CircularProgress, {circularProgressClasses} from "@mui/material/CircularProgress";
import FullSizeImage from "./FullSizeImage";
import "../Config/ConfigI18n.tsx";
import {useTranslation} from "react-i18next";

interface PropsDropZone {
    files: any
    onChange: any
    maxFiles: number
    format?: string
    maxHeight?: number
    variant: string
}

const DropZone = (props: PropsDropZone) => {
    const {t} = useTranslation();
    const MaxHeight = 280
    const MaxOffset = 45
    const CalcHeight = props.maxHeight ? props.maxHeight : MaxHeight
    const CalcHeightImg = CalcHeight - MaxOffset
    const [error, setError] = useState("")
    const [show, setShow] = useState(false)
    const [imageFullSize, setImageFullSize] = useState({link: "", name: ""})
    const [showProgress, setShowProgress] = useState(false)
    const [showRemoveProgress, setShowRemoveProgress] = useState(-1)

    const {getRootProps, getInputProps,} = useDropzone({
        onDrop: acceptedFiles => handleFiles(acceptedFiles),
        useFsAccessApi: false,
        accept: {
            'image/png': ['.jpg', '.jpeg', '.png']
        },
        maxFiles: props.maxFiles,
    })

    const removeImage = (id: number) => {
        const files = props.files
        removeFile(id).then(() => {
            const newFiles = files.filter((file: any) => {
                return file.id !== id
            })
            props.onChange(newFiles)
        })
    }

    const RenderProgress = () => {
        return <Box sx={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            width: props.format ? CalcHeight * 4 : CalcHeight,
            height: CalcHeightImg,
            backgroundColor: "lightgray"
        }}>
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color: '#1a90ff',
                    animationDuration: '550ms',
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={120}
                thickness={6}
            />
        </Box>
    }

    const RenderThumb = () => {
        console.log(props.files)
        return props.files.map((item: any) => {
            return <Card
                sx={{
                    width: props.format ? CalcHeight * 4 : CalcHeight,
                    height: CalcHeight
                }}
                key={item.id}>
                {showRemoveProgress === item.id ? <RenderProgress/> :
                    <CardMedia
                        title={item.name}
                        image={item.thumb}
                        sx={{
                            width: props.format ? CalcHeight * 4 : CalcHeight,
                            height: CalcHeightImg
                        }}
                        onClick={() => {
                            setImageFullSize(item)
                            setShow(true)
                        }}
                    />
                }
                <CardContent sx={{m: 0.5, p: 0.1}}>
                    {showRemoveProgress === item.id ?
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                fontSize: "10px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: '13rem'
                            }}
                        >
                            Removing file...
                        </Typography>
                        :
                        <Stack direction="row">
                            <Stack sx={{flex: 1}}>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    noWrap
                                    sx={{
                                        fontSize: "10px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: props.format ? CalcHeight * 4 - 50 : CalcHeight - 50
                                    }}>
                                    Name: {item.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    noWrap
                                    sx={{
                                        fontSize: "10px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: props.format ? CalcHeight * 4 - 50 : CalcHeight - 50
                                    }}>
                                    Id: {item.id} / Link: {item.link}
                                </Typography>
                            </Stack>
                            <IconButton size="small" color="error" onClick={() => removeImage(item.id)}>
                                <RemoveCircleRoundedIcon/>
                            </IconButton>
                        </Stack>
                    }
                </CardContent>
            </Card>
        })
    }

    const RenderDropBox = () => {
        return <Card
            key={"empty"}
            sx={{
                width: props.format ? CalcHeight * 4 : CalcHeight,
                height: CalcHeight
            }}
        >
            <span {...getRootProps()}>
                 {showProgress ? <RenderProgress/> :
                     <CardMedia
                         title="Empty Image"
                         image={"/images/dummy_image.png"}
                         sx={{
                             width: props.format ? CalcHeight * 4 : CalcHeight,
                             height: CalcHeightImg
                         }}
                     />
                 }
                <CardContent sx={{m: 0.5, p: 0.1, flex: '1 0 auto'}}>
                    <input {...getInputProps()} />
                    {error ?
                        <Typography
                            variant="body2"
                            color="text.error"
                            sx={{
                                fontSize: "10px"
                            }}>
                            {String(error)}
                        </Typography>
                        :
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                fontSize: "10px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: '13rem'
                            }}
                        >
                            {showProgress ?
                                <span>{t("photo.process")}</span>
                                :
                                <span>{(t("photo.upload"))}&nbsp;
                                    <strong>Maximum: {props.maxFiles}</strong>
                                </span>
                            }
                        </Typography>
                    }
                </CardContent>
            </span>
        </Card>
    }

    const handleFiles = async (acceptedFiles: any[]) => {
        setShowProgress(true)
        const formData = new FormData();
        acceptedFiles.forEach(file => {
            formData.append('files', file);
        })
        const resp = await fetch(ConfigGeneral.API_URL + "/file/photo?variant=" + props.variant, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("st-jwt")
            },
        })
        if (!resp.ok) {
            setError(resp.statusText)
            setShowProgress(false)
        }
        return await resp.json().then((data: { payload: any[]; }) => {
            const newFiles = data.payload.map((file) => {
                return file
            })
            if (props.files) {
                newFiles.push(...props.files)
            }
            props.onChange(newFiles)
            setError("")
            setShowProgress(false)
        }).catch((error: any) => {
            setError(error)
            setShowProgress(false)
        })
    }

    const removeFile = async (id: number) => {
        setShowRemoveProgress(id)
        const resp = await fetch(ConfigGeneral.API_URL + "/file/photo/" + id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("st-jwt")
            },
        })
        if (!resp.ok) {
            setError(resp.statusText)
            setShowRemoveProgress(-1)
        }
        return await resp.json().then(() => {
            setShowRemoveProgress(-1)
        }).catch((error) => {
            setError(error)
            setShowRemoveProgress(-1)
        })

    }

    const showDropBox = () => {
        if (typeof props.files === "undefined") return true
        const count = !!(props.files && props.files.length < props.maxFiles)
        const link = !(props.files && props.files.length === 1 && props.files[0] && props.files[0].link)
        if (link) return true
        return count
    }

    const showThump = () => {
        const count = !!(props.files && props.files.length > 0)
        const link = !(props.files && props.files.length > 0 && props.files[0] && props.files[0].link)
        if (link) return false
        return count
    }

    return <Stack direction="row" useFlexGap flexWrap="wrap" gap={1}>
        {showThump() ? <RenderThumb/> : ""}
        {showDropBox() ? RenderDropBox() : ""}
        <FullSizeImage src={imageFullSize.link} name={imageFullSize.name} open={show} close={() => setShow(false)}/>
    </Stack>
}

export default DropZone