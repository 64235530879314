import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TourApi from "../../../../Api/TourApi";
import ResizeBox from "../../../../Components/ResizeBox";
import {DataGrid, GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import ResponsiveImage from "../../../../Components/ResponsiveImage";
import MgmtPageTitle from "../../Components/MgmtPageTitle";
import MgmtPageActions from "../../Components/MgmtPageActions.tsx";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Message from "../../../../Components/Message.tsx";
import {ButtonGroup, IconButton} from "@mui/material";

const TourGroupOverview = () => {
    const navigate = useNavigate();
    const [groups, setGroups] = React.useState([TourApi.TourGroupDefinition]);
    const [changed, setChanged] = React.useState(false)

    useEffect(() => {
        TourApi.GetGroups().then((data: any) => {
            if (data) {
                setGroups(data.payload.map((p: any) => {
                    return {...p, actionID: p.id}
                }))
            }
            setChanged(false)
        })
    }, [changed]);

    interface MessageProps {
        severity?: string
        message?: string
        open: boolean
    }

    const emptyMessage: MessageProps = {
        severity: "success",
        message: "I'm ok",
        open: false
    }

    const [message, setMessage] = useState<MessageProps>(emptyMessage)

    const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([])
    const onRowClick = (id: any) => {
        setRowSelection(id)
    }

    const isDisabled = (id:number) => {
        return !rowSelection.includes(id)
    }

    const onDeleteGroup = (id: number) => {
        TourApi.DeleteGroup(id).then((data: any) => {
            if (data) {
                setRowSelection([])
                setGroups(groups.filter((p: any) => p.id !== id))
                setMessage({
                    severity: "success",
                    message: "Photo deleted",
                    open: true
                })
            }
        })
    }


    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "cardPhoto",
            headerName: "Image",
            headerClassName: 'tableHeader',
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const url = params.value && params.value.thumb ? params.value.thumb : ""
                return <ResponsiveImage src={url} sx={{height: "50px"}}/>
            }
        },
        {
            field: 'orderID',
            headerName: 'Order',
            headerClassName: 'tableHeader',
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return params.value ? String(params.value) : "0"
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'subtitle',
            headerName: 'Subtitle',
            flex: 2,
            headerClassName: 'tableHeader',
        },
        {
            field: "active",
            headerName: "Active",
            align: "center",
            headerAlign: "center",
            flex: 0.5,
            headerClassName: "tableHeader",
            renderCell: (params) => {
                return params.value ? "yes" : "no"
            }
        },
        {
            field: "actionID",
            headerName: "",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
                return <ButtonGroup variant="text" aria-label="Tour group action button group">
                    <IconButton sx={{m: 0}} onClick={() => {
                        navigate("/mgmt/tour/group/edit/" + params.value, {replace: true})
                    }}>
                        <EditOutlinedIcon color="action" fontSize="small"/>
                    </IconButton>
                    <IconButton sx={{m: 0}} onClick={() => {
                        onDeleteGroup(params.value)
                    }} disabled={isDisabled(params.value)}>
                        <DeleteIcon color={isDisabled(params.value)?"disabled":"warning"} fontSize="small"/>
                    </IconButton>
                </ButtonGroup>
            }
        },
    ];

    const actions = [
        {label: "mgmtTourGroup.addGroup", onClick: () => navigate("/mgmt/tour/group/edit", {replace: true})},
    ]

    return <ResizeBox.Box>
        <MgmtPageTitle label="mgmtTourGroup.groups" icon="holiday_village"/>
        <MgmtPageActions actions={actions}/>
        <DataGrid
            sx={{height: ResizeBox.Height}}
            autoPageSize
            rowHeight={39}
            columnHeaderHeight={39}
            rows={groups}
            columns={columns}
            checkboxSelection
            disableMultipleRowSelection={true}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            onRowSelectionModelChange={(i: any) => {
                onRowClick(i)
            }}
            rowSelectionModel={rowSelection}
        />
        <Message message={message} close={() => setMessage({open: false})} open={message.open}/>
    </ResizeBox.Box>
}

export default TourGroupOverview