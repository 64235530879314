import {Stack, Typography} from "@mui/material";
import React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import FlightLandIcon from "@mui/icons-material/FlightLand";
import EuroIcon from "@mui/icons-material/Euro";
import PublicIcon from "@mui/icons-material/Public";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

interface Props {
    label: string
    value: string
    icon: any
}

const PictogramInfo = (props: Props) => {
    const {label, value, icon} = props

    const selectIcon = (icon: string) => {
        const iconStyle = {
            fontSize: "3.0rem", paddingRight: "0.5rem", marginTop: "5px", color: {sm: "rgb(51,51,51,0.8)", md: "whitesmoke"}
        }
        switch (icon) {
            case "CalendarMonthIcon" : {
                return <CalendarMonthIcon sx={iconStyle}/>
            }
            case "FlightLandIcon" : {
                return <FlightLandIcon sx={iconStyle}/>
            }
            case "FlightTakeoffIcon" : {
                return <FlightTakeoffIcon sx={iconStyle}/>
            }
            case "EuroIcon" : {
                return <EuroIcon sx={iconStyle}/>
            }
            case "PublicIcon" : {
                return <PublicIcon sx={iconStyle}/>
            }
            default : {
                return <RadioButtonCheckedIcon sx={iconStyle}/>
            }
        }
    }
    return <Stack direction={"row"} paddingRight={3} paddingLeft={3}>
        {selectIcon(icon)}
        <Stack direction={"column"}>
            <Typography sx={{color: {sm: "rgb(51,51,51,0.8)", md: "whitesmoke"}}} variant="subtitle1">{label}</Typography>
            <Typography sx={{color: {sm: "rgb(51,51,51,0.8)", md: "whitesmoke"}}} variant="h6">{value}</Typography>
        </Stack>
    </Stack>
}

export default PictogramInfo