import React from "react"
import {Paper, Stack, Typography} from "@mui/material";
import Utils from "../../Common/Utils";

interface Props {
    info: any
}

const TourInfo = (props: Props) => {
    const {info} = props

    const InfoItems = () => {
        const nw = Utils.calcMaxWidthCharacters(info, "name")
        return <Stack mt={2}>
            {info.map((item: any, index: number) => {
                if (!item.name || !item.text) return ''
                return <Stack direction={"row"} key={index} mt={0.5} justifyItems={"center"} textAlign={"left"}
                              alignItems={"top"} gap={1}>
                    <Typography variant={"body1"} sx={{fontWeight: 600, mr: 1, width: nw}}>
                        {item.name}:
                    </Typography>
                    <Typography variant={"body1"} flex={1}>{item.text}</Typography>
                </Stack>
            })}
        </Stack>
    }

    return <Paper elevation={0} sx={{
        background: "rgb(69,147,198,0.2)",
        p: 2,
        height: "100%"
    }}
    >
        <Typography variant='h6'> Allgemeine Infos</Typography>
        <InfoItems/>
    </Paper>
}

export default TourInfo