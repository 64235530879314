import React, {useEffect, useState} from "react";
import PhotoApi from "../../../Api/PhotoApi";
import {
    Button,
    Card,
    CardContent,
    CardMedia, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle, Divider, FormControl, FormControlLabel,
    IconButton,
    Stack,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ProgressiveImage from "../../../Components/ProgressiveImage";
import ConfigGeneral from "../../../Config/ConfigGeneral";


interface Props {
    photos: any
    open: any
    close: any
    setMessage: any
    selected: any
}

const DialogPhotoDelete = (props: Props) => {

    const {selected, photos, open, close, setMessage} = props
    const [finalSelection, setFinalSelection] = useState<number[]>([])
    const [isDisabled, setIsDisabled] = useState<boolean>(false)

    useEffect(() => {
        if (open && finalSelection.length === 0) {
            setFinalSelection(selected)
        }
    }, [finalSelection.length, open, selected])

    const preview: undefined[] = []
    if (photos.length > 0) {
        selected.forEach((s: number) => {
            preview.push(photos.find((p: any) => {
                return p.id === s
            }))
        })
    }

    const onSelectChange = (id: number, checked: boolean) => {
        const newSelection = [...finalSelection]
        if (checked) {
            newSelection.push(id)
        } else {
            const index = newSelection.indexOf(id);
            if (index > -1) {
                newSelection.splice(index, 1);
            }
        }
        if (newSelection.length > 0) {
            setFinalSelection(newSelection)
        }
    }

    // TODO: parse response for error messages
    const onDelete = () => {
        setIsDisabled(true)
        PhotoApi.DeleteBulkPhoto(finalSelection).then((response: any) => {
            console.log("response: " +JSON.stringify(response))
            setMessage({
                severity: "success",
                message: `Deleted ${finalSelection.length} photo(s) successfully `,
                open: true
            })
            close(true)
            setFinalSelection([])
            setIsDisabled(false)
        }).catch((error: string) => {
            setMessage({
                severity: "error",
                message: `Error deleting photo ids ${finalSelection} with error ${error}`,
                open: true
            })
            close(true)
            setFinalSelection([])
            setIsDisabled(false)
        })

    }

    return <Dialog
        open={open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => close(false)}>
        <DialogTitle sx={{padding: "10px 12px"}}>
            Delete Photos
            <IconButton
                aria-label="close"
                onClick={() => close(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}>
                <CloseRoundedIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{padding: "10px 12px"}}>
            <Stack direction="row" gap={1} useFlexGap flexWrap="wrap" mt={2}>
                {preview.map((p: any) => {
                    return <Card key={p.id}>
                        <CardMedia>
                            <ProgressiveImage src={p.thumb} placeholder={ConfigGeneral.URL_DUMMY_PHOTO}
                                              style={{height: "120px"}}/>
                        </CardMedia>
                        <CardContent sx={{
                            padding: "0px 10px 0px 10px",
                            "&:last-child": {
                                paddingBottom: 0
                            }
                        }}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={finalSelection.includes(p.id)}
                                            onChange={(_, d) => onSelectChange(p.id, d)}
                                        />
                                    }
                                    label="Selected"
                                />
                            </FormControl>
                        </CardContent>
                    </Card>
                })}
            </Stack>
            <Divider sx={{mt: 4}}/>
            <Stack direction="row-reverse">
                <Button onClick={() => onDelete()} color="error" disabled={finalSelection.length === 0 || isDisabled}>
                    Delete
                </Button>
                <Button onClick={() => {
                    setFinalSelection([])
                    close(false)
                }}>
                    Cancel
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default DialogPhotoDelete