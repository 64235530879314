import React, {useEffect, useState} from "react"
import {FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import API from "../../../../../Api/Api";
import {TFunction} from "i18next";

interface Props {
    t:TFunction
    onSelect: any
    selected: any
    filter: string
}

const SelectTextModule = (props: Props) => {
    const t=props.t
    interface TextModule {
        id: number | null
        type: string
        name: string
        text: string
    }

    const [selected, setSelected] = useState<TextModule>(props.selected)
    const [textModules, setTextModules] = useState<TextModule[]>([])

    useEffect(() => {
        if (textModules.length === 0) {
            API.Request('/tour/text').then((d: any) => {
                const modules = d.payload.filter((d: any) => d.type === props.filter || d.type === "universal")
                setTextModules(modules)
            })
        }
    }, [props, textModules])

    const onSelect = (value: any) => {
        setSelected(value)
        props.onSelect(textModules.find((t) => t.id === value))
    }

    return <FormControl variant="standard" sx={{mt: 1,  width: "100%"}}>
        <InputLabel>{t("mgmtTourEdit.textModule")}</InputLabel>
        <Select
            id="textModule"
            name="textModule"
            variant="standard"
            value={selected ? selected : ""}
            margin="dense"
            onChange={(event) => onSelect(event.target.value)}
            label={t("mgmtTourEdit.type")}
            SelectDisplayProps={{
                style: {display: 'flex', alignItems: 'center'},
            }}
        >
            {textModules.map((option: any) => {
                return <MenuItem value={option.id} key={option.id}>
                    <Typography variant="subtitle2">Name:</Typography>
                    <Typography variant="body1" ml={1}> {option.name}</Typography>
                    <Typography variant="subtitle2" ml={2}>Text:</Typography>
                    <Typography variant="body1" ml={1}> {option.text}</Typography>
                </MenuItem>
            })}
        </Select>
    </FormControl>
}

export default SelectTextModule