import React, {useEffect, useState} from "react"
import TourApi from "../../Api/TourApi";
import PhotoBar from "./PhotoBar/PhotoBar";
import {
    Fade, Typography, Container, useScrollTrigger, Stack, createTheme,
    Paper,
} from "@mui/material";
import HeaderTour from "./HeaderTour";
import HeaderBar from "./HeaderBar";
import TourProgram from "./TourProgram";
import TourTextList from "./TourTextList";
import TourPrice from "./TourPrice";
import TourGroupDates from "./TourGroupDates";
import TourService from "./TourService";
import TourInfo from "./TourInfo";
import Utils from "../../Common/Utils.tsx";

interface Props {
    id: number
}

const ViewTour = (props: Props) => {

    const [tour, setTour] = useState(TourApi.TourDefinition)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading)
            TourApi.GetPublicTour(props.id).then((d: any) => {
                setTour(d.payload)
                setLoading(false)
            })
    }, [setTour, loading, props.id])

    const joinToString = (a: any, name: string) => {
        if (loading) return ""
        if (!a) return ""
        const s = a.map((i: any) => {
            return i[name]
        })
        return s.join(", ")
    }

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 650,
    })

    const triggerProgram = useScrollTrigger({
        disableHysteresis: true,
    })

    const triggerInfo = useScrollTrigger({
        disableHysteresis: true,
    })

    const triggerService = useScrollTrigger({
        disableHysteresis: true,
    })

    const triggerPhoto = useScrollTrigger({
        disableHysteresis: true,
    })

    // Modify the tour header font size for mobiles
    const theme = createTheme();
    theme.typography.h3 = {
        fontSize: '1.4rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
        },
        '@media (min-width:750px)': {
            fontSize: '1.6rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.8rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.6rem',
        },
    }

    const combineImpressions = () => {
        const combined = []
        if (tour.photo) combined.push(...tour.photo)
        if (tour.photoSet && tour.photoSet.photo) combined.push(...tour.photoSet.photo)
        return combined
    }

    const addGeneralInfo = () => {
        const info = []
        info.push({name: "Tour Code", text: tour.code})
        if (tour.language) info.push({name: "Toursprache", text: joinToString(tour.language, "name")})
        // if (tour.guidedBy) info.push({name: "Reiseleitung", text: tour.guidedBy})
        if (tour.accommodation) info.push({name: "Unterbringung", text: tour.accommodation})
        if (tour.season) info.push({name: "Reisezeit", text: Utils.makeSeason(tour.season)})
        if (tour.info && tour.info.length > 0) info.push(...tour.info)
        return info
    }

    const RenderCharacter = () => {
        return <Paper
            elevation={0}
            sx={{
                background: "rgb(69,147,198,0.08)",
                p: 2,
                flex: 0.4,
                height: "100%",
                mt: "1rem"
            }}
        >
            <Typography variant='h6'>Charakter</Typography>
            <Typography variant='body1' textAlign='justify' mt={1}>{tour.character}</Typography>
        </Paper>
    }

    const RenderInfo = () => {
        return <Paper
            elevation={0}
            sx={{
                background: "rgb(69,147,198,0.08)",
                p: 2,
                flex: 0.4,
                height: "100%",
                mt: "1rem"
            }}
        >
            <Typography variant='h6'>Hinweise zur Reise</Typography>
            {tour.info && tour.info.length > 0 ?
                tour.info.map((item: any, index: number) => {
                    if (!item.title || !item.item) return ''
                    return <Stack key={index} mt={0.5} textAlign={"left"} alignItems={"top"}>
                        <Typography variant={"body1"} sx={{fontWeight: 600}}>
                            {item.title}
                        </Typography>
                        <Typography variant={"body1"} flex={1} textAlign={'justify'} >{item.item}</Typography>
                    </Stack>
                })
                : ""}
        </Paper>
    }

    return <>
        {console.log(JSON.stringify(tour.photo))}
        {trigger ? <HeaderBar title={tour.name} showNavigation={true}/> : ""}
        <HeaderTour tour={tour}/>
        <Container>
            <Typography variant="h3" sx={{
                color: "rgb(51,51,51,0.8)",
                textAlign: "center",
                fontWeight: 500,
                wordBreak: "break-word",
                mt: "1rem"
            }}>
                {tour.name}
            </Typography>
            <Typography variant="body1" textAlign="justify" sx={{mt: "1rem"}} fontSize={"1.2rem"}>
                {tour.description}
            </Typography>

            <Stack id="tour-info" mt={"1rem"}>
                <TourInfo info={addGeneralInfo()}/>
            </Stack>

            <Fade in={triggerProgram} timeout={2000}>
                <div>
                    {tour.program ? <TourProgram program={tour.program}/> : ''}
                </div>
            </Fade>

            <Fade in={triggerService} timeout={2000}>
                <Stack id="tour-service" mt={"1rem"}>
                    {tour.include && (
                        <TourService
                            title="Was ist inkludiert"
                            items={tour.include}
                            textModules={tour.textInclude}
                        />
                    )}
                    <div className="pd1"/>
                    {tour.exclude && (
                        <TourService
                            title="Was ist nicht inkludiert"
                            items={tour.exclude}
                            textModules={tour.textExclude}
                        />
                    )}
                </Stack>
            </Fade>

            <Fade in={triggerInfo} timeout={2000}>
                <Stack id="tour-info" mt={"1rem"}>
                    {tour.character ? <RenderCharacter/> : ''}
                </Stack>
            </Fade>

            {tour.dates && (
                <Paper elevation={0} sx={{p: 2, mt: "1rem"}} id='tour-group-price'>
                    <TourGroupDates dates={tour.dates}/>
                    <TourPrice filter="group" price={tour.price}/>
                    <TourTextList text={tour.textDate} addons={tour.dateAddon}/>
                </Paper>
            )}

            {tour.price && (
                <Paper elevation={0} sx={{p: 2, mt: "1rem"}} id='tour-individual-price'>
                    <TourPrice filter="pax" price={tour.price}/>
                    <TourPrice filter="option" price={tour.price}/>
                    <TourTextList text={tour.textIndividual} addons={tour.individualAddon}/>
                </Paper>
            )}

            <Fade in={triggerInfo} timeout={2000}>
                <Stack id="tour-info" mt={"1rem"}>
                    {tour.info ? <RenderInfo/> : ''}
                </Stack>
            </Fade>

            <Fade in={triggerPhoto} timeout={2000}>
                <Stack id="tour-photo" mt={"1rem"}>
                    {tour.photo ? <PhotoBar photos={combineImpressions()}/> : ''}
                </Stack>
            </Fade>

        </Container>

    </>
}

export default ViewTour
