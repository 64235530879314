import React, {useEffect} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    ListItemIcon, ListItemText,
    MenuItem, Paper,
    Select,
    Stack, TextField, Typography
} from "@mui/material";
import ConfigTour from "../../../../../Config/ConfigTour.tsx";
import {TFunction} from "i18next";
import {useImmer} from "use-immer";
import SelectTextModule from "./SelectTextModule.tsx";

interface Props {
    open: boolean
    show: any
    day: ProgramItem | undefined
    index: number | undefined
    onSave: any
    t: TFunction
}

interface ProgramItem {
    id: number | null
    type: string
    headline: string
    description: string
    text: any
    order: number
    toDelete: boolean
}

const DialogEditProgramInfo = (props: Props) => {

    const t = props.t
    const [day, setDay] = useImmer<ProgramItem | undefined>(undefined)
    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        if (props.open && loading) {
            setDay(props.day)
            setLoading(false)
        }
    }, [props.day, props.open,  setDay, loading, setLoading])

    const onChange = (e: any) => {
        const value = e.target.value ? e.target.value : ""
        const key = e.target.name ? e.target.name : ""
        setDay((draft: any) => {
            switch (key) {
                case "description": {
                    draft.description = value
                    break
                }
            }
            switch (key) {
                case "headline": {
                    draft.headline = value
                    break
                }
            }
        })
    }

    const onSelectTextModule = (value: any) => {
        setDay((draft: any) => {
            draft.description = value.text
            draft.headline = value.name
        })
    }

    const canSave = () => {
        return !Boolean(day && day.type && day.description && day.headline)
    }

    const renderDayType = () => {
        const dayType = ConfigTour.ProgramTypeOptions.filter(
            (t) => t.value === "info")
        return dayType.map((item: any, index: number) => {
            return <MenuItem value={item.value} key={index}>
                <ListItemIcon sx={{minWidth: 32}}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.label)} sx={{my: 0}}/>
            </MenuItem>
        })
    }

    return <Dialog open={props.open} fullWidth maxWidth={"lg"}>
        <DialogTitle>{t("mgmtTourEdit.addProgramItem")}</DialogTitle>
        <DialogContent>
            <Stack direction="column" width={"100%"}>
                <Stack direction="row" useFlexGap>
                    <FormControl variant="standard" sx={{m: 1, minWidth: "15ch"}}>
                        <InputLabel>{t("mgmtTourEdit.type")}</InputLabel>
                        <Select
                            id="type"
                            name="type"
                            value={"info"}
                            variant="standard"
                            margin="dense"
                            label={t("mgmtTourEdit.type")}
                            SelectDisplayProps={{
                                style: {display: 'flex', alignItems: 'center'},
                            }}
                        >
                            {renderDayType()}
                        </Select>
                    </FormControl>
                </Stack>
                <TextField
                    fullWidth
                    value={day && day.headline ? day.headline : ''}
                    margin="dense"
                    id="headline"
                    name="headline"
                    label={t("mgmtTourEdit.headline")}
                    type="text"
                    variant="standard"
                    helperText={t("mgmtTourEdit.helperHeadline")}
                    onChange={onChange}
                />
                <Paper variant="outlined" sx={{mt: 3, padding: 1, backgroundColor: "#EEE"}}>
                    <Typography variant={"subtitle1"}>{t("mgmtTourEdit.headerAddTextModule")}</Typography>
                    <Typography variant={"caption"}>
                        {t("mgmtTourEdit.characterHelp")}</Typography>
                    <SelectTextModule
                        t={t}
                        onSelect={onSelectTextModule}
                        selected={null}
                        filter={"program"}
                    />
                    <TextField
                        fullWidth
                        value={day && day.description ? day.description : ''}
                        margin="dense"
                        id="description"
                        name="description"
                        label={t("generics.description")}
                        type="text"
                        multiline
                        variant="standard"
                        rows={5}
                        sx={{mt: 2}}
                        onChange={onChange}
                    />
                </Paper>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Stack direction="row">
                <Button onClick={() => {
                    setDay(undefined)
                    setLoading(true)
                    props.show(false)
                }}>
                    {t("generics.cancel")}
                </Button>
                <Button disabled={canSave()} onClick={() => {
                    props.onSave(day, props.index)
                    setDay(undefined)
                    setLoading(true)
                    props.show(false)
                }}>
                    {t("generics.save")}
                </Button>
            </Stack>
        </DialogActions>
    </Dialog>
}

export default DialogEditProgramInfo;