import API from "./Api";

export interface PhotoType {
    id: number | null,
    name: string,
    description: string,
    thumb: string,
    link: string,
    size: number,
    variant: string,
    mediaType: string,
    store: {
        type: string
    },
    objectID: string
}

export interface PhotoSetType {
    id: number | null,
    name: string,
    description: string,
    photo: PhotoType[]
}

const PhotoApi: any = {

    DefaultPhoto: {
        id: null,
        name: "",
        description: "",
        thumb: "",
        link: "",
        size: 0,
        variant: "",
        mediaType: "",
        store: {
            type: ""
        },
        objectID: ""
    },

    DefaultPhotoSet: {
        id: null,
        name: "",
        description: "",
        photo: []
    },

    GetPhotos: () => {
        return API.Request('/file/photo')
    },

    GetVariant: (variant: string) => {
        return API.Request('/file/photo?variant=' + variant)
    },

    GetPhotoDetail: (id: number) => {
        return API.Request('/file/photo/' + id)
    },

    PersistPhoto: async (data: any) => {
        return API.Persist(data.id, '/file/photo', data)
    },

    GetPhotoSets: () => {
        return API.Request('/file/photoSet')
    },

    GetPhotoSetDetail: (id: number) => {
        return API.Request('/file/photoSet/' + id)
    },


    PersistPhotoSet: async (data: any) => {
        return API.Persist(data.id, '/file/photoSet', data)
    },

    DeletePhoto: async (id: number) => {
        return API.Delete('/file/photo/' + id)
    },

    DeleteBulkPhoto: async (ids: number[]) => {
        return API.Delete('/file/photos?ids=' + ids.join())
    },

    DeletePhotoSet: async (id: number) => {
        return API.Delete('/file/photoSet/' + id)
    },

}

export default PhotoApi