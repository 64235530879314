const Utils: any = {

    calcMaxWidthCharacters: (objects: any, field: string) => {
        let nw = 0
        objects.forEach((e: any): void => {
            if (e[field] && e[field].length > nw) {
                nw = e[field].length
            }
        })
        return nw + "ch"
    },

    // makeId generates a random hash id with the provided length
    // Default length = 6
    makeId: (length?: number) => {
        const maxLength = length ? length : 6
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < maxLength) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    },

    makeSeason: (season: any) => {
        const months = []
        let yearAround = season.yearAround ? "Diese Reise ist ganzjährig buchbar" : ""

        if (season.january) months.push("Januar")
        if (season.february) months.push("Februar")
        if (season.march) months.push("März")
        if (season.april) months.push("April")
        if (season.may) months.push("Mai")
        if (season.june) months.push("Juni")
        if (season.july) months.push("Juli")
        if (season.august) months.push("August")
        if (season.september) months.push("September")
        if (season.october) months.push("Oktober")
        if (season.november) months.push("November")
        if (season.december) months.push("Dezember")

        if (months.length > 0 && season.yearAround) {
            yearAround = yearAround + ", außer im " + months.join(", ")
        }

        return yearAround ? yearAround : "Buchbar im " + months.join(", ")
    }
}

export default Utils