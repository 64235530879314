import React, {useEffect} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, InputAdornment,
    InputLabel,
    ListItemIcon, ListItemText,
    MenuItem,
    Select,
    Stack, TextField
} from "@mui/material";
import ConfigTour from "../../../../../Config/ConfigTour.tsx";
import {TFunction} from "i18next";
import {useImmer} from "use-immer";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import TerrainOutlinedIcon from "@mui/icons-material/TerrainOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";

interface Props {
    open: boolean
    show: any
    day: ProgramItem | undefined
    index: number | undefined
    onSave: any
    t: TFunction
}

interface ProgramItem {
    id: number | null
    headline: string
    prefix: string
    type: string
    description: string
    accommodation: string
    activity: string
    transit: string
    text: any
    order: number
    toDelete: boolean
}

const DialogEditProgramDay = (props: Props) => {

    const t = props.t
    const [day, setDay] = useImmer<ProgramItem | undefined>(undefined)
    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        if (props.open && loading) {
            setDay(props.day)
            setLoading(false)
        }
    }, [props.day, props.open, setDay, loading, setLoading])

    const onChange = (e: any) => {
        const value = e.target.value ? e.target.value : ""
        const key = e.target.name ? e.target.name : ""
        setDay((draft: any) => {
            switch (key) {
                case "headline": {
                    draft.headline = value
                    break
                }
                case "description": {
                    draft.description = value
                    break
                }
                case "type": {
                    draft.type = value
                    break
                }
                case "prefix": {
                    draft.prefix = value
                    break
                }
                case "accommodation": {
                    draft.accommodation = value
                    break
                }
                case "activity": {
                    draft.activity = value
                    break
                }
                case "transit": {
                    draft.transit = value
                    break
                }
            }
        })
    }

    const canSave = () => {
        return !Boolean(day && day.type && day.prefix && day.headline)
    }

    const renderDayType = () => {
        const dayType = ConfigTour.ProgramTypeOptions.filter(
            (t) => t.value !== "info")
        return dayType.map((item: any, index: number) => {
            return <MenuItem value={item.value} key={index}>
                <ListItemIcon sx={{minWidth: 32}}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.label)} sx={{my: 0}}/>
            </MenuItem>
        })
    }

    return <Dialog open={props.open} fullWidth maxWidth={"lg"}>
        <DialogTitle>
            {t("mgmtTourEdit.addProgramItem")}
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" width={"100%"}>
                <Stack direction="row" useFlexGap>
                    <TextField
                        value={day && day.prefix ? day.prefix : ''}
                        margin="dense"
                        id="prefix"
                        name="prefix"
                        label="Prefix"
                        type="text"
                        variant="standard"
                        helperText={t("mgmtTourEdit.helperPrefix")}
                        onChange={onChange}
                        sx={{width: "15ch"}}
                    />
                    <FormControl variant="standard" sx={{m: 1, minWidth: "15ch"}}>
                        <InputLabel>{t("mgmtTourEdit.type")}</InputLabel>
                        <Select
                            id="type"
                            name="type"
                            value={day && day.type ? day.type : ""}
                            variant="standard"
                            margin="dense"
                            onChange={onChange}
                            label={t("mgmtTourEdit.type")}
                            SelectDisplayProps={{
                                style: {display: 'flex', alignItems: 'center'},
                            }}
                        >
                            <MenuItem value="">
                                <em>{t("mgmtTourEdit.selectType")}</em>
                            </MenuItem>
                            {renderDayType()}
                        </Select>
                    </FormControl>
                </Stack>
                <TextField
                    fullWidth
                    value={day && day.headline ? day.headline : ''}
                    margin="dense"
                    id="headline"
                    name="headline"
                    label={t("mgmtTourEdit.headline")}
                    type="text"
                    variant="standard"
                    helperText={t("mgmtTourEdit.helperHeadline")}
                    onChange={onChange}
                />
                <TextField
                    fullWidth
                    value={day && day.description ? day.description : ''}
                    margin="dense"
                    id="description"
                    name="description"
                    label={t("generics.description")}
                    type="text"
                    multiline
                    variant="filled"
                    rows={5}
                    onChange={onChange}
                />
                <Stack direction="row" useFlexGap gap={1}>
                    <TextField
                        value={day && day.accommodation ? day.accommodation : ''}
                        margin="dense"
                        id="accommodation"
                        name="accommodation"
                        label={t("mgmtTourEdit.accommodation")}
                        type="text"
                        variant="standard"
                        helperText={t("mgmtTourEdit.helperAccommodation")}
                        onChange={onChange}
                        sx={{flex: 1}}
                        InputProps={{
                            startAdornment: <InputAdornment
                                position="start"><HotelOutlinedIcon/></InputAdornment>,
                        }}
                    />
                    <TextField
                        value={day && day.activity ? day.activity : ''}
                        margin="dense"
                        id="activity"
                        name="activity"
                        label={t("mgmtTourEdit.activity")}
                        type="text"
                        variant="standard"
                        helperText={t("mgmtTourEdit.helperActivity")}
                        onChange={onChange}
                        sx={{flex: 1}}
                        InputProps={{
                            startAdornment: <InputAdornment
                                position="start"><TerrainOutlinedIcon/></InputAdornment>,
                        }}
                    />
                    <TextField
                        value={day && day.transit ? day.transit : ''}
                        margin="dense"
                        id="transit"
                        name="transit"
                        label={t("mgmtTourEdit.transit")}
                        type="text"
                        variant="standard"
                        helperText={t("mgmtTourEdit.helperTransit")}
                        onChange={onChange}
                        sx={{flex: 1}}
                        InputProps={{
                            startAdornment: <InputAdornment
                                position="start"><DirectionsCarFilledOutlinedIcon/></InputAdornment>,
                        }}
                    />
                </Stack>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Stack direction="row">
                <Button onClick={() => {
                    setDay(undefined)
                    setLoading(true)
                    props.show(false)
                }}>
                    {t("generics.cancel")}
                </Button>
                <Button disabled={canSave()} onClick={() => {
                    props.onSave(day, props.index)
                    setDay(undefined)
                    setLoading(true)
                    props.show(false)
                }}>
                    {t("generics.save")}
                </Button>
            </Stack>
        </DialogActions>
    </Dialog>
}

export default DialogEditProgramDay;