import React from "react";
import CardTourGroup from "./Tour/CardTourGroup";
import {Container, Fade, Stack, Typography} from "@mui/material";
import ResponsiveImage from "../Components/ResponsiveImage";
import "../Config/ConfigI18n.tsx";
import {useTranslation} from "react-i18next";

const Home = () => {

    const {t} = useTranslation();

    const imageList = [
        "/assets/header/Alaska_Seward_Silvertip_Tours_gr.jpg",
        "/assets/header/Kanada_Haida_Gwaii_Kajak_Silvertip_Tours_gr.jpg",
        "/assets/header/Kanada_Peggys_Cove_Silvertip_Tours_gr.jpg",
        "/assets/header/Kanada_Vancouver_Island_Kajak_Silvertip_Tours_gr.jpg",
        "/assets/header/Kanada_Vancouver_Island_Tofino_Bike_Silvertip_Tours_gr.jpg",
        "/assets/header/Peru_Choquequirao_Trekking_Lamas_Silvertip_Tours_gr.jpg",
        "/assets/header/Peru_Lares_Lake_Silvertip_Tours_gr.jpg",
        "/assets/header/Peru_Machu_Picchu_Fotograph_Silvertip_Tours_gr.jpg",
        "/assets/header/Peru_Machu_Picchu_Silvertip_Tours_gr.jpg",
        "/assets/header/Peru_Machu_Picchu_Trekking_Silvertip_Tours_gr.jpg",
        "/assets/header/Peru_Trekking_Ausangate_7_Lakes_und_Rainbow_Mountain_Silvertip_Tours_gr.jpg",
        "/assets/header/Winter_Jamtal_Splitboard_Silvertip_Tours_gr.jpg",
        "/assets/header/Winter_Whistler_Snowboard_Silvertip_Tours_gr.jpg",
        "/assets/header/Winter_Wildspitze_Skitour_Silvertip_Tours_gr.jpg",
    ];

    // TODO: get image list from database and load images from google store
    const getRandomImage = (imageList: string[]) => {
        return imageList[Math.floor(Math.random() * imageList.length)]
    };

    return <>
        <Fade in={true} timeout={2000}>
            <Stack>
                <ResponsiveImage src={getRandomImage(imageList)} nobg={true}/>
            </Stack>
        </Fade>
        <Container>
            <Stack id='tour_headline' mt={"1rem"} textAlign='justify'>
                <Typography variant='h5'>{t("home.headline")}</Typography>
            </Stack>
            <Stack id='tour_greating' mt={"1rem"}>
                <Typography variant='body1' sx={{fontSize: '1.2rem'}} textAlign='justify'>
                    Unser Angebot richtet sich an vielseitig interessierte und aktive Menschen. Bei unseren
                    Touren kombinieren wir sportliche Aktivitäten (Biken, Wandern, Skitouren u.v.m.) mit
                    kulturellen Begegnungen und einer Portion süßem Nichts-Tun. Sammeln Sie neue Erfahrungen, lernen
                    Sie
                    Gleichgesinnte kennen, und gehen Sie auf Tuchfühlung mit Land und Leuten.
                    Doch bevor es los geht, nehmen wir uns Zeit für Ihre individuelle Beratung, denn wir
                    sind überzeugt, dass dies die beste Grundlage für die erfolgreiche Auswahl Ihres nächsten
                    Abenteuers
                    ist.
                </Typography>
                <Typography variant='body1' sx={{fontWeight: 600, pt: 4, fontSize: '1.1rem'}}>
                    Ihr Team von Silvertip Tours
                </Typography>
            </Stack>
            <Stack id='tour_search' mt={"2rem"}>
                {/*TODO eigene komponente und Search Icon, eventuell anderer text*/}
                <div style={{flex: 1, textAlign: "center"}}>
                    <div className="headingTitle">Lust auf Abenteuer</div>
                    <div className="headingSubTitle">Silvertip: Rising Stronger, Shining Brighter!</div>
                </div>
            </Stack>
            <Stack id='tour_groups' mt={"2rem"}>
                <CardTourGroup/>
            </Stack>
        </Container>
    </>
}

export default Home