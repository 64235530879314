import React, {useEffect, useState} from "react"
import TourApi from "../../Api/TourApi";
import {useNavigate} from "react-router-dom";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Fade,
    Grid,
    Stack,
    Typography,
    useScrollTrigger
} from "@mui/material";
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";

const CardTourGroup = () => {

    const navigate = useNavigate()
    const [tourGroups, setTourGroups] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading)
            TourApi.GetPublicGroup().then((d: any) => {
                setTourGroups(d.payload)
                setLoading(false)
            })
    }, [tourGroups, loading])

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    const renderCard = (index: number, group: any) => {
        return <Card key={index} sx={{background: "rgb(102,102,102,1)", color: "white"}}>
            <CardActionArea onClick={() => navigate(group.route.route)}>
                {group.cardPhoto.link ?
                    <CardMedia sx={{width: "100%", height: "100%", objectFit: "cover"}}
                               component="img"
                               alt={group.cardPhoto.name}
                               image={group.cardPhoto.link}
                    /> : ''}
                <CardContent sx={{minHeight: "6rem"}}>
                    <Typography sx={{overflow: "hidden", textOverflow: "ellipsis", fontVariant: "small-caps"}}
                                gutterBottom
                                variant="h5"
                                noWrap
                    >
                        {group.name}
                    </Typography>
                    <Typography sx={{overflow: "hidden", textOverflow: "ellipsis"}}
                                variant="subtitle1"
                                color="white"
                                noWrap
                    >
                        {group.subtitle}
                    </Typography>
                    {group.tour ?
                        <Stack direction="row" alignItems="center" alignContent="center">
                            <CardTravelOutlinedIcon sx={{fontSize: "small", color: "lightgray", mr: 1}}/>
                            <Typography
                                variant="subtitle2"
                                color="lightgray"
                                sx={{overflow: "hidden", textOverflow: "ellipsis"}}
                            >
                                {group.tour.length + ' Reisen gefunden'}
                            </Typography>
                        </Stack> : ''}
                </CardContent>
            </CardActionArea>
        </Card>
    }

    return <Fade in={trigger} timeout={2000}>
        <Grid container spacing={{xs: 1, lg: 2}} columns={{xs: 1, sm: 2, md: 3, lg: 3}}>
            {tourGroups ? tourGroups.map((group: any, index: number) => {
                return <Grid item xs={1} key={group.id}>
                    {renderCard(index, group)}
                </Grid>
            }) : ""}
        </Grid>
    </Fade>
}

export default CardTourGroup