import React from "react"
import {
    Box, Button,
    Card,
    CardMedia,
    createTheme,
    Fade, Stack,
} from "@mui/material";
import PictogramInfo from "./PhotoBar/PictogramInfo";
import transform from "../../Common/Transform";

interface Props {
    tour: any
}

const HeaderTour = (props: Props) => {
    const tour = props.tour
    const headerImageLink = tour.headerPhoto && tour.headerPhoto.link ? tour.headerPhoto.link : 'dummy'
    const theme = createTheme();
    theme.typography.h3 = {
        fontSize: '1.4rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
        },
        '@media (min-width:750px)': {
            fontSize: '2rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '5rem',
        },
    };

    const joinToString = (a: any, name: string) => {
        if (!a) return ""
        const s = a.map((i: any) => {
            return i[name]
        })
        return s.join(", ")
    }

    const reduceStartEnd = (start: string, end: string) => {
        if (start === end) {
            return start
        }
        return start + "/" + end
    }

    return <Fade in={true} timeout={2000}>
        <div>
            <Card style={{
                position: "relative",
                justifyContent: "center",
                background: "rgb(51,51,51,0.8)",
                color: "white"
            }}>
                <CardMedia image={headerImageLink} sx={{width: "auto", height: "560px"}} title={tour.name}/>
                <Box position={"absolute"}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     height="150px"
                     style={{
                         top: "75%",
                         width: "100%",
                     }}
                >
                    <Box justifyContent="center"
                         alignItems="center"
                         sx={{
                             background: "rgb(51,51,51,0.8)",
                             padding: "15px 35px",
                             borderRadius: "10px",
                             flexGrow: 0.2,
                             display: {xs: 'none', md: 'flex'},
                             width: {xs: 'xs', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl'}
                         }}
                    >
                        <Stack direction={"row"} useFlexGap flexWrap="wrap" justifyContent={"center"}>
                            <PictogramInfo
                                label={"Dauer"}
                                value={tour.nights + 1 + " Tage"}
                                icon={"CalendarMonthIcon"}/>
                            <PictogramInfo
                                label={"Start/Ende"}
                                value={reduceStartEnd(tour.startPlace, tour.finishPlace)}
                                icon={"FlightTakeoffIcon"}
                            />
                            <PictogramInfo
                                label={"Länder"}
                                value={joinToString(tour.country, "name")}
                                icon={"PublicIcon"}
                            />
                            <PictogramInfo
                                label={"Preis ab"}
                                value={transform.FormatPrice(tour.startPrice)}
                                icon={"EuroIcon"}
                            />
                        </Stack>
                    </Box>
                </Box>
            </Card>

            <Stack direction="column" useFlexGap flexWrap="wrap" justifyContent="center" mt="2em"
                   sx={{display: {sm: 'flex', md: 'none'}}}
            >
                <PictogramInfo
                    label={"Dauer"}
                    value={tour.nights + " Tage"}
                    icon={"CalendarMonthIcon"}/>
                <PictogramInfo
                    label={"Start/Ende"}
                    value={reduceStartEnd(tour.startPlace, tour.finishPlace)}
                    icon={"FlightLandIcon"}
                />
                <PictogramInfo
                    label={"Länder"}
                    value={joinToString(tour.country, "name")}
                    icon={"PublicIcon"}
                />
                <PictogramInfo
                    label={"Preis ab"}
                    value={transform.FormatPrice(tour.startPrice)}
                    icon={"EuroIcon"}
                />
            </Stack>

            <Stack direction={"row"} sx={{
                justifyContent: "center",
                textAlign: "center",
                background: "white"
            }}>
                <Button variant="text" color="primary" size="large">Reiseverlauf</Button>
                <Button variant="text" color="primary" size="large">Leistungen</Button>
                <Button variant="text" color="primary" size="large">Termine und Preise</Button>
                <Button variant="text" color="primary" size="large">Informationen</Button>
                <Button variant="text" color="primary" size="large">Impressionen</Button>
            </Stack>
        </div>
    </Fade>
}

export default HeaderTour