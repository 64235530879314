import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";
import {t} from "i18next";

interface Props {
    open: any
    onClose: any
    title: string
    content?:string
}

const MgmtDialogYesNo = (props: Props) => {

    const {open, onClose, title, content} = props

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="mgmt-yesno-dialog-title"
        aria-describedby="mgmt-yesno-dialog-description"
    >
        <DialogTitle id="mgmt-yesno-dialog-title">
            {title}
        </DialogTitle>
        {content && (
        <DialogContent dividers>
            <DialogContentText id="mgmt-yesno-dialog-description">
                {content}
            </DialogContentText>
        </DialogContent>
        )}
        <DialogActions>
            <Button onClick={()=>onClose(true)} color="warning">
                {t("generics.yes")}
            </Button>
            <Button onClick={()=>onClose(false)} autoFocus>
                {t("generics.no")}
            </Button>
        </DialogActions>
    </Dialog>
}

export default MgmtDialogYesNo
