import React, {useEffect, useMemo, useState} from "react"
import {useImmer} from "use-immer";
import {Stack} from "@mui/material";

import ProgramTimeline from "../../../../Components/ProgramTimeline";
import {TFunction} from "i18next";
import MgmtPageActions from "../../Components/MgmtPageActions.tsx";
import {ArrowDownward, ArrowUpward, Delete, Edit} from "@mui/icons-material";
import DialogEditProgramDay from "./Components/DialogEditProgramDay.tsx";
import DialogEditProgramInfo from "./Components/DialogEditProgramInfo.tsx";

interface Props {
    t: TFunction
    onChange: any
    value: any
}

const TourEditProgram = (props: Props) => {

    const t = props.t

    interface ProgramItem {
        id: number | null
        headline: string
        prefix: string
        type: string
        description: string
        accommodation: string
        activity: string
        transit: string
        text: any
        order: number
        toDelete: boolean
    }

    const item: ProgramItem = useMemo(() => {
        return {
            id: null,
            headline: "",
            prefix: "",
            type: "",
            description: "",
            accommodation: "",
            activity: "",
            transit: "",
            text: [],
            order: 0,
            toDelete: false
        }
    }, [])

    const [showDialogProgramDay, setShowDialogProgramDay] = useState<boolean>(false)
    const [showDialogProgramInfo, setShowDialogProgramInfo] = useState<boolean>(false)
    const [program, setProgram] = useImmer<ProgramItem[]>([])
    const [newProgramItem, setNewProgramItem] = useImmer<ProgramItem>(item)
    const [addProgramItem, setAddProgramItem] = useState(false)
    const [day, setDay] = useImmer<ProgramItem | undefined>(undefined)
    const [index, setIndex] = useImmer<number | undefined>(undefined)
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        if (addProgramItem) {
            if (program && program.length > 0) {
                setProgram(draft => {
                    draft.push(newProgramItem)
                })
            } else {
                setProgram([newProgramItem])
            }
            setAddProgramItem(false)
            setNewProgramItem(item)
            setUpdate(true)
        }
    }, [addProgramItem, program, setNewProgramItem, newProgramItem, setProgram, item])

    useEffect(() => {
        if (update) {
            props.onChange(program)
            setUpdate(false)
        }
    }, [update, program, props])

    useEffect(() => {
        setProgram(props.value)
    }, [props, setProgram])


    const removeDeletedItems = (program: ProgramItem[]) => {
        return program.filter((item: ProgramItem) => !item.toDelete)
    }

    const onDeleteItem = (index: number) => {
        if (program) {
            if (program[index].id) {
                setProgram(draft => {
                    draft[index].toDelete = true
                })
            } else {
                setProgram(draft => {
                    draft.splice(index, 1)
                })
            }
            setUpdate(true)
        }
    }

    const onChangeProgramDay = (day: any, index: number | undefined) => {
        if (index === undefined) {
            if (props.value === undefined || props.value.length === 0) {
                setProgram([{...day}])
            } else {
                setProgram(draft => {
                    draft.push(day)
                })
            }
        } else {
            setProgram(draft => {
                draft[index] = day
            })
        }
        setUpdate(true)
    }

    const swapItem = (index: number, direction: string) => {
        setProgram((draft) => {
            switch (direction) {
                case "up": {
                    const up = {...program[index]}
                    const down = {...program[index - 1]}
                    up.order = program[index - 1].order
                    down.order = program[index].order
                    draft[index - 1] = up
                    draft[index] = down
                    break
                }
                case "down": {
                    const down = {...program[index]}
                    const up = {...program[index + 1]}
                    down.order = program[index + 1].order
                    up.order = program[index].order
                    draft[index + 1] = down
                    draft[index] = up
                    break
                }
            }
        })
        setUpdate(true)
    }

    const nexDay = () => {
        return program ? program.filter((d: any) => d.type !== 'info').length + 1 : 1
    }

    const renderActionButton = (index: number) => {
        return <Stack direction="row" gap={1}>
            {index > 0 ?
                <ArrowUpward fontSize='small' onClick={() => swapItem(index, "up")}/>
                : ''}
            {index < program.length - 1 ?
                <ArrowDownward fontSize='small' onClick={() => swapItem(index, "down")}/>
                : ''}
            <Edit
                name='edit'
                fontSize='small'
                onClick={() => {
                    setDay(program[index])
                    setIndex(index)
                }}/>
            <Delete
                name='trash'
                color="warning"
                fontSize='small'
                onClick={() => onDeleteItem(index)}/>
        </Stack>
    }

    useEffect(() => {
        if (day && index !== undefined) {
            if (program[index]?.type === 'info') {
                setShowDialogProgramInfo(true)
            } else {
                setShowDialogProgramDay(true)
            }
        }
    }, [day, index, program])

    const onCancel = () => {
        setDay(undefined)
        setIndex(undefined)
        setShowDialogProgramDay(false)
        setShowDialogProgramInfo(false)
    }

    const renderProgram = (program: any) => {
        return <ProgramTimeline program={removeDeletedItems(program)} actionButton={renderActionButton}/>
    }

    const actions = [
        {
            label: "mgmtTourEdit.addDay", onClick: () => {
                const newDay = item
                const order = program ? program.length === 0 ? 1 : program[program.length - 1].order + 1 : 1
                setDay({...newDay, prefix: "Tag " + nexDay(), order: order})
                setShowDialogProgramDay(true)
            }
        },
        {
            label: "mgmtTourEdit.addTextModule", onClick: () => {
                const newDay = item
                const order = program ? program.length === 0 ? 1 : program[program.length - 1].order + 1 : 1
                setDay({...newDay, order: order, type: "info"})
                setShowDialogProgramInfo(true)
            }
        }
    ]

    return <Stack>
        <MgmtPageActions actions={actions}/>
        {program ? renderProgram(program) : ""}
        <DialogEditProgramDay
            open={showDialogProgramDay}
            show={onCancel}
            t={t}
            onSave={onChangeProgramDay}
            day={day}
            index={index}/>
        <DialogEditProgramInfo
            open={showDialogProgramInfo}
            show={onCancel}
            t={t}
            onSave={onChangeProgramDay}
            day={day}
            index={index}/>
    </Stack>
}

export default TourEditProgram