import React, {useEffect, useState} from "react"
import Api from "../../../Api/Api";
import {useNavigate} from "react-router-dom";
import {DataGrid, GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import ResizeBox from "../../../Components/ResizeBox";
import MgmtPageTitle from "../Components/MgmtPageTitle.tsx";
import MgmtPageActions from "../Components/MgmtPageActions.tsx";
import {ButtonGroup, IconButton} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import TourApi from "../../../Api/TourApi.tsx";
import Message from "../../../Components/Message.tsx";


const TextModules = () => {

    interface TextModule {
        id: number | null
        name: string | null
        type: string | null
        text: string | null
    }

    const navigate = useNavigate();
    const [textModules, setTextModules] = React.useState<TextModule[]>([]);
    const [changed, setChanged] = React.useState(false)

    useEffect(() => {
        Api.Request('/tour/text').then((data: any) => {
                if (data) {
                    setTextModules(data.payload.map((p: any) => {
                        return {...p, actionID: p.id}
                    }))
                    setChanged(false)
                }
            }
        )
    }, [changed]);

    interface MessageProps {
        severity?: string
        message?: string
        open: boolean
    }

    const emptyMessage: MessageProps = {
        severity: "success",
        message: "I'm ok",
        open: false
    }

    const [message, setMessage] = useState<MessageProps>(emptyMessage)

    const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([])
    const onRowClick = (id: any) => {
        setRowSelection(id)
    }

    const isDisabled = (id: number) => {
        return !rowSelection.includes(id)
    }

    const onDeleteTextModule = (id: number) => {
        TourApi.DeleteText(id).then(() => {
            setRowSelection([])
            setTextModules(textModules.filter((p: any) => p.id !== id))
            setMessage({
                severity: "success",
                message: "Text module deleted",
                open: true
            })
        }).catch((error: string) => {
            setMessage({
                severity: "error",
                message: "Error: " + error,
                open: true
            })
        })
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.2,
            headerClassName: 'tableHeader',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'text',
            headerName: 'Text',
            flex: 2,
            headerClassName: 'tableHeader',
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.5,
            headerClassName: 'tableHeader',
        },
        {
            field: "actionID",
            headerName: "",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
                return <ButtonGroup variant="text" aria-label="Textmodul action button group">
                    <IconButton sx={{m: 0}} onClick={() => {
                        navigate("/mgmt/textModules/edit/" + params.value, {replace: true})
                    }}>
                        <EditOutlinedIcon color="action" fontSize="small"/>
                    </IconButton>
                    <IconButton sx={{m: 0}} onClick={() => {
                        onDeleteTextModule(params.value)
                    }} disabled={isDisabled(params.value)}>
                        <DeleteIcon color={isDisabled(params.value) ? "disabled" : "warning"} fontSize="small"/>
                    </IconButton>
                </ButtonGroup>
            }
        },
    ];

    const actions = [
        {label: "mgmtTourEdit.addTextModule", onClick: () => navigate("/mgmt/textModules/edit", {replace: true})},
    ]

    return <ResizeBox.Box>
        <MgmtPageTitle label={"mgmtSkeleton.textModule"} icon={"text_snippet"}/>
        <MgmtPageActions actions={actions}/>

        <DataGrid
            sx={{height: ResizeBox.Height}}
            autoPageSize
            rows={textModules}
            columns={columns}
            checkboxSelection
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            onRowSelectionModelChange={(i: any) => {
                onRowClick(i)
            }}
            rowSelectionModel={rowSelection}
            disableMultipleRowSelection={true}
            rowHeight={39}
            columnHeaderHeight={39}
        />
        <Message message={message} close={() => setMessage({open: false})} open={message.open}/>
    </ResizeBox.Box>
}

export default TextModules