import {
    Box,
    Card,
    CardActionArea, CardActions,
    CardContent,
    CardMedia,
    Fade,
    Grid,
    Typography,
    useScrollTrigger
} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import Transform from "../../Common/Transform";

interface Props {
    tours: any
}

const CardTour = (props: Props) => {
    const tours = props.tours
    const navigate = useNavigate()

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    return <Fade in={trigger} timeout={2000}>
        <Grid container spacing={{xs: 1, lg: 2}} columns={{xs: 1, sm: 2, md: 3, lg: 3}}>
            {tours ? tours.map((tour: any, index: number) => {
                return <Grid item xs={1} key={tour.id}>
                    <Card key={index}>
                        <CardActionArea onClick={() => navigate(tour.route.route, {replace: true})}>
                            <Box sx={{position: 'relative'}}>
                                {tour.cardPhoto.link ?
                                    <CardMedia
                                        sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                                        component="img"
                                        alt={tour.cardPhoto.name}
                                        image={tour.cardPhoto.link}
                                    /> : ""}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: '95%',
                                        backgroundColor: 'rgba(102, 102, 102, 0.7)',
                                        color: 'white',
                                        padding: '5px 15px',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "2",
                                            WebkitBoxOrient: "vertical",
                                            fontVariant: "small-caps"
                                        }}>
                                        {tour.name}
                                    </Typography>
                                </Box>
                            </Box>
                            <CardContent>
                                <Typography
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "3",
                                        WebkitBoxOrient: "vertical",
                                        minHeight: "4.5em",
                                    }}>
                                    {tour.description}
                                </Typography>
                                <CardActions>
                                    <Typography
                                        color="primary"
                                        sx={{
                                            marginLeft: 'auto',
                                            padding: 0
                                        }}
                                    >
                                        {tour.startPrice && tour.nights ?
                                            tour.nights + 1 + " Tage ab " + Transform.FormatPrice(tour.startPrice)
                                            : "Hier Anfragen"}
                                    </Typography>
                                </CardActions>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            }) : ""}
        </Grid>
    </Fade>

}

export default CardTour