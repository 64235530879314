import React, {useState} from "react"
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, IconButton, InputAdornment, Stack, TextField,
} from "@mui/material";
import {useImmer} from "use-immer";
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Transform from "../../../../Common/Transform";
import {TFunction} from "i18next";
import MgmtPageActions from "../../Components/MgmtPageActions.tsx";

interface Props {
    t: TFunction
    price: any
    onChange: any
    isGroup?: boolean
    isOption?: boolean
}

const TourEditPrice = (props: Props) => {

    const t = props.t

    const isGroup = !!props.isGroup
    const isOption = !!props.isOption
    const isPax = !isGroup && !isOption

    interface Price {
        id: number | null
        actionID: number | null
        min: number | null
        max: number | null
        name: string | null
        price: number | null
        isPax: boolean
        isOption: boolean
        isGroup: boolean
        description: string | null
    }

    const emptyPrice: Price = {
        id: null,
        actionID: null,
        min: null,
        max: null,
        name: null,
        price: null,
        isPax: isPax,
        isOption: isOption,
        isGroup: isGroup,
        description: null
    }

    const getRows = () => {
        if (!props.price) return []
        // remove toDelete rows
        const filtered = props.price.filter((d: any) =>
            !d.toDelete && d.isGroup === isGroup && d.isPax === isPax && d.isOption === isOption)
        // Sort field name as string
        const sortedA = filtered.sort((a: any, b: any) => {
            if (!a.name || !b.name) return 0
            return a.name.localeCompare(b.name)
        })
        // Sort field min as number
        const sorted = sortedA.sort((a: any, b: any) => a.min < b.min ? -1 : a.min > b.min ? 1 : 0)
        // add unit and actionID
        return sorted.map((d: any) => {
            const unit = d.min && d.max ? d.min + "-" + d.max : d.min ? d.min : "-"
            return {...d, unit: unit, actionID: d.id ? d.id : d.actionID}
        })
    }

    const [showDialogOption, setShowDialogOption] = useState(false)
    const [showDialogPrice, setShowDialogPrice] = useState(false)
    const [newPrice, setNewPrice] = useImmer<Price>(emptyPrice)
    const [edit, setEdit] = useState(-1)
    const [tmpNumber, setTmpNumber] = useState("")

    const onChange = (event: any) => {
        const name = event.target.name ? event.target.name : ""
        const value = event.target.value ? event.target.value : ""
        const checked = event.target.checked
        if (!name) return
        setNewPrice((draft) => {
            switch (name) {
                case "min" : {
                    draft.min = Number(value)
                    break
                }
                case "max" : {
                    draft.max = Number(value)
                    break
                }
                case "name" : {
                    draft.name = value
                    break
                }
                case "price" : {
                    draft.price = Number(value.replace(",", "."))
                    break
                }
                case "description" : {
                    draft.description = value
                    break
                }
                case "isPax" : {
                    draft.isPax = checked
                    draft.name = newPrice.name ? newPrice.name : "pro Teilnehmer"
                    if (newPrice.isOption && checked) {
                        draft.isOption = false
                    }
                    break
                }
                case "isOption" : {
                    draft.isOption = checked
                    if (newPrice.isPax && checked) {
                        draft.isPax = false
                        draft.name = null
                    }
                    break
                }
            }
        })

    }

    const onPriceChange = (event: any) => {
        const value = event.target.value ? event.target.value : ""
        setNewPrice((draft) => {
            draft.price = null
        })
        setTmpNumber(value.replace(".", ","))
    }

    const deletePrice = (actionID: number) => {
        const removeNewItems = props.price.filter((item: any) => item.actionID !== actionID)
        const priceSet = removeNewItems.map((p: any) => {
            if (p.id === actionID) {
                return {...p, toDelete: true}
            }
            return {...p}
        })
        props.onChange(priceSet)
        setNewPrice(emptyPrice)
    }

    const savePrice = (action: string) => {
        if (!newPrice) return
        const priceSet = props.price ? props.price.map((p: Price) => {
            return p
        }) : []
        switch (action) {
            case "save" : {
                const toAdd = {
                    ...newPrice,
                    actionID: props.price ? props.price.length : 0,
                    isGroup: isGroup,
                    isPax: isPax,
                    isOption: isOption
                }
                priceSet.push(toAdd)
                break
            }
            case "update" : {
                const index = props.price.findIndex((p: Price) => p.id === edit)
                priceSet[index] = newPrice
                break
            }
        }
        props.onChange(priceSet)
        setNewPrice(emptyPrice)
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "unit",
            headerName: t("mgmtTourEdit.paxUnit"),
            headerClassName: 'tableHeader',
        },
        {
            field: "name",
            headerName: "Name",
            headerClassName: 'tableHeader',
            flex: 0.5,
        },
        {
            field: "description",
            headerName: t("generics.description"),
            headerClassName: 'tableHeader',
            flex: 1,
        },
        {
            field: "price",
            headerName: t("generics.pPax"),
            headerClassName: 'tableHeader',
            align: "right",
            headerAlign: "right",
            flex: 0.5,
            renderCell: (params) => {
                return Transform.FormatPrice(params.value)
            }
        },
        {
            field: "actionID",
            headerName: "",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
                return <Stack direction="row">
                    <IconButton onClick={() => {
                        setEdit(params.value)
                        setNewPrice(props.price.find((p: Price) => p.id ? p.id === params.value : p.actionID === params.value))
                        if (isPax) {
                            setShowDialogPrice(true)
                        } else {
                            setShowDialogOption(true)
                        }
                    }}>
                        <EditCalendarOutlinedIcon color="action" fontSize="small"/>
                    </IconButton>
                    <IconButton onClick={() => {
                        deletePrice(params.value)
                    }}>
                        <DeleteOutlineRoundedIcon color="warning" fontSize="small"/>
                    </IconButton>
                </Stack>
            }
        },
    ]

    const RenderPriceTable = () => {
        return <DataGrid
            autoHeight
            getRowId={(row) => {
                return row.actionID
            }}
            rows={getRows()}
            rowHeight={39}
            columnHeaderHeight={39}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: props.isGroup ? 5 : 10,
                    },
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        unit: isPax,
                        name: !isPax,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            sx={{width: "100%"}}
        />
    }

    const RenderActionDialog = ({close}: any) => {
        return <DialogActions>
            <Button onClick={() => {
                setEdit(-1)
                setNewPrice(emptyPrice)
                setTmpNumber("")
                close(false)
            }}>
                {t("generics.cancel")}
            </Button>
            {edit >= 0 ?
                <Button onClick={() => {
                    setEdit(-1)
                    savePrice("update")
                    setTmpNumber("")
                    close(false)
                }}>
                    {t("generics.update")}
                </Button>
                :
                <Stack direction="row">
                    <Button onClick={() => {
                        savePrice("save")
                        setTmpNumber("")
                    }}>
                        {t("generics.save")}
                    </Button>
                    <Button onClick={() => {
                        savePrice("save")
                        setTmpNumber("")
                        close(false)
                    }}>
                        {t("generics.saveClose")}
                    </Button>
                </Stack>
            }
        </DialogActions>
    }

    const DialogPrice = () => {
        return <Dialog
            open={showDialogPrice}
            maxWidth={"lg"}
            onClose={() => {
                setNewPrice(emptyPrice)
                setShowDialogPrice(false)
            }}>
            <DialogTitle>
                {t("mgmtTourEdit.addPrice")}
            </DialogTitle>
            <DialogContent sx={{minWidth: "700px"}}>
                <Stack direction="row" gap={1} useFlexGap mt={2}>
                    <TextField
                        sx={{width: "20ch"}}
                        value={newPrice.min ? newPrice.min : ""}
                        margin="dense"
                        id="min"
                        name="min"
                        label={t("generics.min")}
                        type="number"
                        variant="standard"
                        required
                        helperText={t("mgmtTourEdit.helperMin")}
                        onChange={onChange}
                        InputProps={{inputProps: {min: 0}}}
                    />
                    <TextField
                        sx={{width: "20ch"}}
                        value={newPrice.max ? newPrice.max : ""}
                        disabled={!newPrice.min}
                        margin="dense"
                        id="max"
                        name="max"
                        label={t("generics.max")}
                        type="number"
                        variant="standard"
                        helperText={t("mgmtTourEdit.helperMax")}
                        onChange={onChange}
                        InputProps={{inputProps: {min: 0}}}
                    />
                    <TextField
                        sx={{width: "20ch"}}
                        value={newPrice.price ? Transform.FormatPrice(newPrice.price) : tmpNumber}
                        margin="dense"
                        id="price"
                        name="price"
                        label={t("generics.price")}
                        type="text"
                        variant="standard"
                        onChange={onPriceChange}
                        onBlur={onChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                    />
                </Stack>
                <Stack direction="row" gap={1} useFlexGap mt={2}>
                    <TextField
                        fullWidth
                        value={newPrice.description ? newPrice.description : ""}
                        margin="dense"
                        id="description"
                        name="description"
                        label={t("generics.description")}
                        type="text"
                        variant="standard"
                        onChange={onChange}
                    />
                </Stack>
            </DialogContent>
            <RenderActionDialog close={setShowDialogPrice}/>
        </Dialog>

    }
    const DialogOption = () => {
        return <Dialog
            open={showDialogOption}
            maxWidth={"lg"}
            onClose={() => {
                setNewPrice(emptyPrice)
                setShowDialogOption(false)
            }}>
            <DialogTitle>
                {t("mgmtTourEdit.addOption")}
            </DialogTitle>
            <DialogContent sx={{minWidth: "700px"}}>
                <Stack direction="row" gap={1} justifyItems="center" alignItems="center" useFlexGap>
                    <TextField
                        sx={{width: "80ch"}}
                        value={newPrice.name ? newPrice.name : ""}
                        margin="dense"
                        id="name"
                        name="name"
                        label={t("mgmtTourEdit.nameDescription")}
                        type="text"
                        variant="standard"
                        onChange={onChange}
                    />
                    <TextField
                        sx={{width: "20ch"}}
                        value={newPrice.price ? Transform.FormatPrice(newPrice.price) : tmpNumber}
                        margin="dense"
                        id="price"
                        name="price"
                        label={t("generics.price")}
                        type="text"
                        variant="standard"
                        onChange={onPriceChange}
                        onBlur={onChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                    />
                </Stack>
                <Stack direction="row" gap={1} useFlexGap mt={2}>
                    <TextField
                        fullWidth
                        value={newPrice.description ? newPrice.description : ""}
                        margin="dense"
                        id="description"
                        name="description"
                        label={t("generics.description")}
                        type="text"
                        variant="standard"
                        onChange={onChange}
                    />
                </Stack>
            </DialogContent>
            <RenderActionDialog close={setShowDialogOption}/>
        </Dialog>
    }

    const actionPrice = [
        {
            label: "mgmtTourEdit.addPrice", onClick: () => {
                setShowDialogPrice(true)
            }
        }
    ]

    const actionOption = [
        {
            label: "mgmtTourEdit.addOption", onClick: () => {
                setShowDialogOption(true)
            }
        }
    ]

    return <Stack>
        {isPax ? <MgmtPageActions actions={actionPrice}/> : <MgmtPageActions actions={actionOption}/>}
        <RenderPriceTable/>
        {DialogPrice()}
        {DialogOption()}
    </Stack>

}

export default TourEditPrice