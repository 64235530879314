import {useImmer} from "use-immer";
import React, {useEffect, useState} from "react";
import PhotoApi from "../../../Api/PhotoApi";
import {
    Button,
    Card,
    CardContent,
    CardMedia, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle, Divider, FormControl, FormControlLabel,
    IconButton,
    Stack,
    TextField
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ProgressiveImage from "../../../Components/ProgressiveImage";
import ConfigGeneral from "../../../Config/ConfigGeneral";


interface Props {
    photos: any
    open: any
    close: any
    setMessage: any
    selected: any
}

const DialogPhotoSet = (props: Props) => {

    interface PhotoSet {
        name: string | null
        description: string | null
        selected: number[]
    }



    const {selected, photos, open, close, setMessage} = props
    const [finalSelection, setFinalSelection] = useState<number[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [photoSet, setPhotoSet] = useImmer<PhotoSet | undefined>(undefined)
    const [preview, setPreview] = useState<any[]>([])

    useEffect(() => {
        const newPhotoSet: PhotoSet = {
            name: null,
            description: null,
            selected: []
        }
        if (open && loading) {
            setPhotoSet(newPhotoSet)
            setFinalSelection(selected)
            setLoading(false)
            setPreview([])
        }
    }, [loading, setLoading, setFinalSelection, setPhotoSet, setPreview, open, selected])

    // cleanup on close
    useEffect(() => {
        if (!open) {
            setLoading(true)
            setPhotoSet(undefined)
            setPreview([])
        }
    }, [open, setLoading, setPhotoSet, setPreview]);

    if (photos.length > 0 && preview.length === 0) {
        selected.forEach((s: number) => {
            const photoFind = photos.find((p: any) => p.id === s)
            if (photoFind) preview.push(photoFind)
        })
    }

    const onChange = (name: string, value: any) => {
        setPhotoSet(draft => {
            if (draft !== undefined) {
                switch (name) {
                    case "name": {
                        draft.name = value
                        break
                    }
                    case "description": {
                        draft.description = value
                        break
                    }
                }
            }
        })
    }

    const onSelectChange = (id: number, checked: boolean) => {
        const newSelection = [...finalSelection]
        if (checked) {
            newSelection.push(id)
        } else {
            const index = newSelection.indexOf(id);
            if (index > -1) {
                newSelection.splice(index, 1);
            }
        }
        setFinalSelection(newSelection)
    }

    const canSave = () => {
        return photoSet && photoSet.name && finalSelection.length > 0
    }

    const onCreate = () => {
        const photo: { id: number; }[] = []
        finalSelection.forEach((item: number) => {
            photo.push({id: item})
        })
        const body = {
            name: photoSet ? photoSet.name : '',
            description: photoSet ? photoSet.description : '',
            photo: photo
        }
        PhotoApi.PersistPhotoSet(body).then(() => {
            setMessage({
                severity: "success",
                message: `Create photo set "${body.name}" successfully `,
                open: true
            })
            close(false)
        }).catch((error: string) => {
            setMessage({
                severity: "error",
                message: `Error creating photo set ${error}`,
                open: true
            })
        })
    }

    return <Dialog
        open={open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => close(false)}>
        <DialogTitle sx={{padding: "10px 12px"}}>
            Create Photo Set
            <IconButton
                aria-label="close"
                onClick={() => close(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}>
                <CloseRoundedIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{padding: "10px 12px"}}>
            <Stack gap={1}>
                <TextField
                    autoFocus
                    required
                    sx={{width: "40ch"}}
                    value={photoSet && photoSet.name ? photoSet.name : ""}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Photo Set Name"
                    helperText="Uniqe Name of the Photo Set"
                    type="text"
                    variant="standard"
                    onChange={(element) => onChange(element.target.name, element.target.value)}
                />
                <TextField
                    fullWidth
                    multiline
                    rows={1}
                    value={photoSet && photoSet.description ? photoSet.description : ""}
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    type="text"
                    helperText="Internal description"
                    variant="filled"
                    onChange={(element) => onChange(element.target.name, element.target.value)}
                />
            </Stack>
            <Stack direction="row" gap={1} useFlexGap flexWrap="wrap" mt={2}>
                {preview.map((p: any) => {
                    return <Card key={p.id}>
                        <CardMedia>
                            <ProgressiveImage src={p.thumb} placeholder={ConfigGeneral.URL_DUMMY_PHOTO}
                                              style={{height: "120px"}}/>
                        </CardMedia>
                        <CardContent sx={{
                            padding: "0px 10px 0px 10px",
                            "&:last-child": {
                                paddingBottom: 0
                            }
                        }}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={finalSelection.includes(p.id)}
                                            onChange={(_, d) => onSelectChange(p.id, d)}
                                        />
                                    }
                                    label="Selected"
                                />
                            </FormControl>
                        </CardContent>
                    </Card>
                })}
            </Stack>
            <Divider sx={{mt: 4}}/>
            <Stack direction="row-reverse">
                <Button onClick={() => onCreate()} disabled={!canSave()}>
                    Create
                </Button>
                <Button onClick={() => {
                    close(false)
                }}>
                    Cancel
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default DialogPhotoSet