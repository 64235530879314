import React from "react";
import {
    Stack,
    List,
    ListItem,
    Box,
    Link,
    Container,
    ListSubheader,
    Typography,
    Button,
    Divider
} from "@mui/material";

const AppFooter = () => {

    interface Items {
        title: string,
        link: string
    }

    const itemsContact: Items[] = [
        {title: 'Tel.: +43-(0)512-209071', link: 'callto:0043512209071'},
        {title: 'Fax: +43-(0)512-209071-10', link: 'faxto:004351220907110'},
        {title: 'Email: info@silvertip.at', link: 'mailto:info@silvertip.at'},
        {title: 'Skype: Silvertip-Tours', link: 'skype:silvertip-tours?call'},
    ]

    const itemsService: Items[] = [
        {title: 'Ihre Anfrage', link: ''},
        {title: 'Reise-Versicherung', link: ''},
        {title: 'River Cruise Destination Management', link: ''},
    ]

    const itemsAbout: Items[] = [
        {title: 'Standort und Öffnungszeiten', link: '/kontakt'},
        {title: 'Team', link: ''},
        {title: 'Blog', link: ''},
    ]


    const renderList = (title: string, items: any) => {
        return <List
            dense={true}
            sx={{fontSize: '0.8em'}}
            subheader={<ListSubheader sx={{background: '#666', color: 'white'}}>{title}</ListSubheader>}
        >
            {items.map((i: Items) => {
                return <ListItem key={i.title}>
                    <Link href={i.link} sx={{color: 'white'}}>{i.title}</Link>
                </ListItem>
            })}
        </List>
    }

    return <Stack id='footer' sx={{background: '#666', mt:8}}>
        <div className="footerImage"/>
        <Container>
            <Stack direction="row" gridColumn={3} gap={2}>
                <Box flex={1}>
                    {renderList('Kontakt', itemsContact)}
                </Box>
                <Box flex={1}>
                    {renderList('Service', itemsService)}
                </Box>
                <Box flex={1}>
                    {renderList('Über Uns', itemsAbout)}
                </Box>
            </Stack>
            <Divider sx={{opacity: 0.4, borderColor: 'lightgray'}}/>
        </Container>
        <Stack direction='row'
               spacing={1}
               divider={<Divider orientation="vertical" flexItem sx={{opacity: 0.4, borderColor: 'lightgray'}}/>}
               justifyContent="center"
               alignItems="center"
        >
            <Button size='small' href='/agb' sx={{color: 'white'}}>AGB</Button>
            <Button size='small' href='/datenschutz' sx={{color: 'white'}}>Datenschutz</Button>
            <Button size='small' href='/impressum' sx={{color: 'white'}}>Impressum</Button>
        </Stack>
        <Stack sx={{mb: 2}}>
            <Typography variant='caption' textAlign='center' color='white'>
                Silvertip Tours GmbH (c) 2023, all rights reserved. Made with love by Silvertip IT-Solutions GmbH
            </Typography>
        </Stack>
    </Stack>
}

export default AppFooter